import React, { useContext, useEffect, useState } from 'react'
import MainDashboard from './MainDashboard/MainDashboard'
import Booking from './Bookings/Booking'
import CreateTeam from './Lawyers/CreateTeam'
import Team from './Lawyers/Team'
import EditTeam from './Lawyers/EditTeam'
import Departments from './Departments/Departments'
import CreateDepartments from './Departments/CreateDepartments'
import EditDepartments from './Departments/EditDepartments'
import Services from './ServicesPage/Services'
import CreateServices from './ServicesPage/CreateServices'
import EditServices from './ServicesPage/EditServices'
import Category from './ServicesCategory/Category'
import CreateCategory from './ServicesCategory/CreateCategory'
import EditCategory from './ServicesCategory/EditCategory'
import News from './News/News'
import CreateNews from './News/CreateNews'
import EditNews from './News/EditNews'
import CreateEvent from './Events/CreateEvent'
import EditEvent from './Events/EditEvent'
import Event from './Events/Events'
import Reviews from './Reviews/Reviews'
import CreateReviews from './Reviews/CreateReviews'
import Elements from './Elements/Elements'
import Gallery from './Gallery/Gallery'
import Faqs from './Faqs/Faqs'
import WebContent from './WebContent/WebContent'
import AppContent from './AppContent/AppContent'
import Setting from './Setting/Setting'
import Quote from './Quote/Quote'
import useFetch from './customHooks/useFetch'
import { Route, Routes } from 'react-router-dom'
import LeftSide from './components/LeftSide'
import Header from './components/Header'
import EditReviews from './Reviews/EditReviews'
import { MainBookingContext } from './context/MainBooking'
import CaseManagement from './CaseManagement/CaseManagement'
import CreateCase from './CaseManagement/CreateCase'
import EditCase from './CaseManagement/EditCase'
import CaseUpdate from './CaseUpdate/CaseUpdate'
import CaseInquires from './CaseInquires/CaseInquires'
import LegalSecretary from './LegalSecretary/LegalSecretary'
import EditLegalSecretary from './LegalSecretary/EditLegalSecretary'
import CreateLegalSecretary from './LegalSecretary/CreateLegalSecretary'
import CreateCaseUpdate from './CaseUpdate/CreateCaseUpdate'

const DashboardInner = () => {
    const { handleBooking } = useContext(MainBookingContext);
    const {loading:loading2,data:data2} = useFetch("dashboard/bookings/en")
    useEffect(() => {
     if(data2) {
        handleBooking(data2?.data?.bookings?.length)
     }
    }, [data2])
    
    const {loading,data} = useFetch("getProfile")
  return (
    <div className='MainDashboard flex  gap-6'>
      <LeftSide />
      <div className="MainDashboard__Right w-[80%] max-lg:w-full max-lg:pl-6">
        <Header loading={loading}  data={data?.data} />
        <div className="MainDashboard__RightBody">
          <Routes>
            <Route path={`/`} element={<MainDashboard  />} />
            <Route path={`/booking`} element={<Booking />} />
            <Route path={`/case`} element={<CaseManagement />} />
            <Route path={`/case/create`} element={<CreateCase />} />
            <Route path={`/case/edit/:id`} element={<EditCase />} />
            <Route path={`/case/update/create`} element={<CreateCaseUpdate />} />
            <Route path={`/case/update`} element={<CaseUpdate />} />
            <Route path={`/case/inquires`} element={<CaseInquires />} />
            <Route path={`/team`} element={<Team />} />
            <Route path={`/team/create`} element={<CreateTeam />} />
            <Route path={`/team/edit/:id`} element={<EditTeam />} />
            <Route path={`/legalsecretary`} element={<LegalSecretary />} />
            <Route path={`/legalsecretary/create`} element={<CreateLegalSecretary />} />
            <Route path={`/legalsecretary/edit/:id`} element={<EditLegalSecretary />} />
            <Route path={`/departments`} element={<Departments />} />
            <Route path={`/departments/create`} element={<CreateDepartments />} />
            <Route path={`/departments/edit/:id`} element={<EditDepartments />} />
            <Route path={`/services`} element={<Services />} />
            <Route path={`/services/create`} element={<CreateServices />} />
            <Route path={`/services/edit/:id`} element={<EditServices />} />
            <Route path={`/service/category`} element={<Category />} />
            <Route path={`/service/category/create`} element={<CreateCategory />} />
            <Route path={`/service/category/edit/:id`} element={<EditCategory />} />
            <Route path={`/news`} element={<News />} />
            <Route path={`/news/create`} element={<CreateNews />} />
            <Route path={`/news/edit/:id`} element={<EditNews />} />
            <Route path={`/events`} element={<Event />} />
            <Route path={`/events/create`} element={<CreateEvent />} />
            <Route path={`/events/edit/:id`} element={<EditEvent />} />
            <Route path={`/reviews`} element={<Reviews />} />
            <Route path={`/reviews/create`} element={<CreateReviews />} />
            <Route path={`/reviews/edit/:id`} element={<EditReviews />} />
            <Route path={`/elements`} element={<Elements />} />
            <Route path={`/gallery`} element={<Gallery />} />
            <Route path={`/faqs`} element={<Faqs />} />
            {/* <Route path={`/laws`} element={<Laws />} /> */}
            <Route path={`/webcontent`} element={<WebContent />} />
            <Route path={`/appcontent`} element={<AppContent />} />
            <Route path={`/setting`} element={<Setting />} />
            <Route path={`/quote`} element={<Quote />} />
          </Routes>
        </div>
      </div>
    </div>
  )
}

export default DashboardInner