import React, { useContext, useEffect, useState } from 'react'
import phonecall from "../dist/webImages/phone-call.svg"
import calendar from "../dist/webImages/calendar.svg"
import clock from "../dist/webImages/wall-clock.svg"
import close from "../dist/webImages/close.svg"
import dayjs from 'dayjs';
import usePost from '../customHooks/usePost'
import { toast } from 'react-toastify'
import { MainLanguageContext } from '../context/MainLanguageContext'
import swal from "sweetalert";
import noimg from '../dist/webImages/no_image.jpg'
import { DatePicker, Select } from 'antd'
import Pusher from 'pusher-js';
import useFetch from '../customHooks/useFetch'
const { Option } = Select;

const BookingModel = ({ data, modelStatus, modelStatusUpdate,submitss }) => {
      const { loading:loading2, data:data2 } = useFetch(`teams/en/100?page=1`);
    const {id,client_id,client_phone,consultant_id,notification_detail,meeting_purpose,description,beverage,booking_status,client_name,client_email,meeting_date,consultant_image,consultant_name,consultant_designation,number_of_attendees,time_slot} = data
    const [timeSlots, setTimeSlots] = useState([])
    const [timeselected, setTimeSelected] = useState("")
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDate2, setSelectedDate2] = useState(null);
    const { mainLanguage } = useContext(MainLanguageContext); 

    const disabledDate = (current) => {
        // Disable all dates before today
        return current && current < dayjs().startOf('day');
      };

      // useEffect(() => {
      //   // Initialize Pusher
      //   const pusher = new Pusher('96a1a9b7ae897a91bf91', {
      //     cluster: 'ap2',
      //     encrypted: true
      //   });
    
      //   // Subscribe to the channel
      //   const channel = pusher.subscribe(`booking-notification-${client_id}-channel`);
    
      //   // Bind a callback for the `pusher:subscription_succeeded` event
      //   channel.bind('pusher:subscription_succeeded', () => {
      //     console.log(`Successfully subscribed to  ${channel.name}`);
      //   });
    
      //   // Bind a callback for the `chat` event
      //   channel.bind('notify-event', (data) => {
      //     console.log('Received message:', data.bookingDetail);
      //     // toast.success(data.bookingDetail?.client_email,
      //     //   data.bookingDetail?.client_email
      //     // );
      //   });
    
      //   // Clean up the subscription when component unmounts
      //   return () => {
      //     channel.unbind_all();
      //     channel.unsubscribe();
      //   };
      // }, []);
  

    const handleCloseModel = () => {
        modelStatusUpdate(false)
    }
    const [reason, setReason] = useState("")
    const handleReason = (e) => {
        setReason(e.target.value)
    }
    const [res, apiMethod] = usePost()
    const [res2, apiMethod2] = usePost()

    
    const [selectedClientId, setSelectedClientId] = useState(consultant_id === 0 ? "" : consultant_id); // State to save the selected client ID

    const handleChange = (value) => {
      setSelectedClientId(value); // Save selected client ID to state
    };



    const handleCancellel = () => {
        let formdata = new FormData();
        formdata.append("booking_status", "cancelled");
        formdata.append("description", reason ?? "");
        formdata.append("consultant_id", selectedClientId);
        if (reason === "") {
            swal({
              title: "Required Fields are empty! Please fill and try again",
              text: "Reason",
              icon: "error",
              dangerMode: true,
            });
          }
          else {
            apiMethod2(`booking/meetingStatus/${id}/${mainLanguage}`,formdata)

          }
    }



    const    handleComplete = () => {
        let formdata = new FormData();
        formdata.append("booking_status", "completed");
        formdata.append("consultant_id", selectedClientId);
        formdata.append("description", reason ?? "");
        if(booking_status == "cancelled") {
          if(selectedDate2) {
            formdata.append("meeting_date", selectedDate2);
          }
          if(timeselected) {
            formdata.append("time_slot", timeselected);
          }
            let checkerRequried = [];
            if (!selectedDate) {
                checkerRequried.push("Date")
              }
            if (!timeselected) {
                checkerRequried.push("Time")
              }
              if (!reason) {
                checkerRequried.push("Reason")
              }
              const meetingDateTime = dayjs(`${meeting_date} ${time_slot.split(' To ')[0]}`);
              const currentDateTime = dayjs();
              console.log(meetingDateTime.isAfter(currentDateTime))
              if (meetingDateTime.isAfter(currentDateTime)) {
                apiMethod(`booking/meetingStatus/${id}/${mainLanguage}`,formdata)
              } 
              
              else {
                if (checkerRequried.length > 0) {
                  swal({
                      title: "Required Fields are empty! Please fill and try again",
                      text: checkerRequried.join(","),
                      icon: "error",
                      dangerMode: true,
                    });
                }
                else {
                  apiMethod(`booking/meetingStatus/${id}/${mainLanguage}`,formdata)
                }
              }
         
        }
        else {

            if (reason === "") {
                swal({
                    title: "Required Fields are empty! Please fill and try again",
                    text: "Reason",
                    icon: "error",
                    dangerMode: true,
                });
          }
          else if (!selectedClientId) {
            swal({
              title: "Required Fields are empty! Please fill and try again",
              text: "Select Cleint",
              icon: "error",
              dangerMode: true,
          });
          }
          else {
              apiMethod(`booking/meetingStatus/${id}/${mainLanguage}`,formdata)     
            }
        }
        }
    
    useEffect(() => {
        if(res.data) {
            const {status,message} = res?.data
            if(status === "false") {
                toast.error(message);
            }
            else {
                submitss()
                modelStatusUpdate(false)
            toast.success(message);
          }
        }
      }, [res.data])
    useEffect(() => {
        if(res2.data) {
            const {status,message} = res2?.data
            if(status === "false") {
                toast.error(message);
            }
            else {
                modelStatusUpdate(false)
            toast.success(message);
          }
        }
      }, [res2.data])
    const formattedDate = dayjs(meeting_date).format('MMMM D, YYYY');
    const [res3, apiMethod3] = usePost()
    const onChange = (date, dateString) => {
      setSelectedDate(date);
      setSelectedDate2(dateString);
      let formdata = new FormData();
      formdata.append("meeting_date", dateString);
      apiMethod3(`timeSlots`, formdata)
      setTimeSlots([])
    };
    useEffect(() => {
      if (res3.data) {
        const { status, message } = res3?.data
        if (status === "false") {
          toast.error(message);
        }
        else {
          const isToday = dayjs().isSame(selectedDate, 'day');
          const currentTime = dayjs();
          
          // Filter time slots for today, removing those before the current time
          const availableSlots = res3.data.data.filter(slot => {
            if (isToday) {
              const [startTime] = slot.time_slot.split(' to ');
              const slotTime = dayjs(startTime, 'h:mm A');
              return slotTime.isAfter(currentTime);
            }
            return true;
          });
          
          setTimeSlots(availableSlots)
        }
      }
    }, [res3.data])
    return (
        <>
            <div onClick={handleCloseModel} className={`backgroundFixed w-full h-screen  fixed top-[50%] left-0 right-0 transform translate-y-[-50%]  bg-black opacity-90 z-10 ${modelStatus ? "block" : "hidden"}`}></div>
            <div className={`bookingModel transition-all duration-300  bg-white rounded-xl fixed z-20  left-0 right-0 mx-auto transform translate-y-[-50%] w-[50%] ${modelStatus ? "opacity-100 top-[50%] active" : "top-[40rem] opacity-0"}`}>
                <div className='closeButton cursor-pointer absolute bg-[#E0EBFF] w-[2.938rem] h-[2.938rem] top-0 right-[-4rem] grid place-items-center rounded-[.7rem] max-lg:right-[-1rem] max-lg:top-[-2rem] ' onClick={handleCloseModel}>
                    <img src={close} alt="" />
                </div>
                <div className='max-h-[80vh] overflow-auto modelBox'>
                    <div className="bookingBox  bg-[#EFF4FD] p-5 rounded-xl">
                        <ul className='list mb-3'>
                            <li className='flex items-center justify-between'>
                            <div className='h2 text-[1.5rem] font-Mluvka flex items-center gap-1'> <span className='bg-primary p-1 text-[1rem] rounded-xl'>#{id}</span> {client_name}</div>  
                            {
                         booking_status === "completed" ?
                        <span className='p-2 text-white rounded-[.5rem] bg-[green] text-[.8rem]'>Completed</span>
                        :
                        booking_status === "cancelled" ?
                        <span className='p-2 text-white rounded-[.5rem] bg-[red] text-[.8rem]'>Cancelled</span>
                        :
                        <span className='p-2 1ext-white rounded-[.5rem] bg-[#B7903B] text-[.8rem]'>Pending</span>
                       }
                            </li>
                        </ul>
                        <div className='grid grid-cols-2'>
                        <div className="h3 mb-3">{client_email}</div>
                            {/* <div className='flex items-center gap-1'><img  src={Coffee} alt="Coffee" /><span className='font-Mluvka text-[.8rem]'>{beverage}</span></div> */}
                        </div>


                       



                      {
                       <div className='grid grid-cols-2'>
                       <div className='calender my-4 flex items-center gap-2'>
                       <img src={calendar} className='w-[1.5rem]' alt="calender" />
                   <span className='font-MluvkaBold text-[.9rem]'>{formattedDate}</span>
               </div>
                 <div className='calender my-4 flex items-center gap-2'>
                 <img src={clock} className='w-[1.5rem]' alt="calender" />
                     <span className='font-MluvkaBold text-[.9rem]'>{time_slot}</span>
                 </div>

                 <div className='calender my-4 flex items-center gap-2'>
                    <img src={phonecall} className='w-[1.5rem]' alt="calender" />
                    <span className='font-MluvkaBold text-[.9rem]'>{client_phone}</span>
                </div>

                 </div>

                      }
                       
              


                       <div className='evening mb-4'>
                    <div className="h4 font-MluvkaBold mb-2">Number of Person</div>
                    <ul className='list flex items-center gap-3'>
                        <li className='flex items-center gap-2 bg-white py-3 px-5 rounded-full'>
<svg className="svg-icon w-[1rem] fill-[#B7903B]" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M78.22291438 968.30107625c0 14.21935406 9.47956969 23.69892375 23.69892374 23.69892375s23.69892375-9.47956969 23.69892375-23.69892375c0-213.33036656 173.03552063-386.36588719 386.36588625-386.36588625s386.37923813 173.03552063 386.37923907 386.36588625c0 14.21935406 9.47956969 23.69892375 23.69892281 23.69892375s23.69892375-9.47956969 23.69892375-23.69892375c0-196.74779531-132.74067469-362.66696344-310.51597969-417.19451437 90.06926062-45.03463031 151.713165-137.52051375 151.713165-244.14564282C786.96091906 155.261105 663.69981406 32 511.98664813 32S237.03908094 155.261105 237.03908094 306.96091906c0 106.66518281 61.6305525 199.1110125 151.69981406 244.14564281C208.60037281 603.25754469 78.22291438 769.17671281 78.22291438 968.30107625z m206.22736499-663.75678c0-125.62432219 101.92539844-227.56307156 227.54972063-227.56307156s227.56307156 101.99215594 227.56307156 227.56307156S637.651025 532.1474225 512 532.1474225s-227.54972063-101.92539844-227.54972063-227.60312625z m0 0" fill="" /></svg>
                              <span className=' text-[.8rem] font-MluvkaBold'>{number_of_attendees}</span></li>
                    </ul>
                </div>
                <div className='evening mb-4'>
                    <div className="h4 font-MluvkaBold text-[.8rem] mb-1">Purpose Of Meeting</div>
                    <ul className='list flex items-center gap-3'>
                        <li className='flex items-center gap-2 bg-white py-3 px-5 rounded-lg'>
                            <p>{meeting_purpose}</p>
                        </li>
                    </ul>
                </div>



{
  booking_status !== "completed"
  &&
  
  <>
  <span className="text-[#95ACD5] font-Mluvka text-[.8rem]">Team</span>
                <Select
  name="id"
  className="w-full rounded-xl h-[2.8rem] px-0 outline-0 capitalize appearance-none max-lg:text-[.9rem]"
  placeholder="Select Team"
  value={selectedClientId}
  onChange={handleChange} // Set the selected value in state
>
  {Array.isArray(data2?.data?.teams) && data2?.data?.teams.map((item, index) => {
    const { id, name, lowyer_image } = item;
    return (
      <Option value={id} key={index}>
        <div className="flex items-center space-x-2">
          <img
            src={lowyer_image} 
            alt={name} 
            onError={(e) => { e.target.src = noimg }}
            className="w-6 h-6 rounded-full object-cover"
          />
          <span>{name}</span>
        </div>
      </Option>
    );
  })}
</Select>
</>
}

{booking_status === "completed" &&

<>

                        <div className='Lawyer my-4 flex items-center gap-2 '>
                            <span className='text-[#95ACD5] font-Mluvka text-[.8rem]'>Team</span>
                            <div className='w-full h-[.1rem] bg-[#C9D4E9]'></div>
                        </div>

                        <div className='bookingprofile flex items-center gap-3'>
                    <div className="bookingprofile__img grid place-items-center bg-[#C9D3E4] rounded-xl">
                        <img src={consultant_image} onError={(e) => { e.target.src = noimg }} className='rounded-lg w-[3.375rem] h-[3.375rem] object-cover' alt="bookingProfile" />
                    </div>
                    <div className="bookingprofile__txt">
                        <div className="h5 font-Mluvka capitalize leading-[1] mb-1">{consultant_name}</div>
                        <div className="h4 text-[.8rem]">{consultant_designation}</div>
                    </div>
                </div>

                </>}
                    </div>
                    <div className='p-4'>
                    
                    {
                       booking_status === "cancelled"  &&
                       <div className='grid grid-cols-2 gap-2 my-3'>
                       <div className="inputBox">
                       <DatePicker
                         className="w-full border border-[#ddd] py-3 px-4 rounded-xl outline-0"
                         onChange={onChange}
                         value={selectedDate}
                         disabledDate={disabledDate}
                         placeholder={"Date"}
                         />
                     </div>
                     <div className="inputBox">
                    <select value={timeselected} onChange={(e) => setTimeSelected(e.target.value)} className="w-full border border-[#ddd] py-3 px-4 rounded-xl outline-0 capitalize" name="time_slot" id="">
                      <option value="">Time Slot</option>
                      {
                        Array.isArray(timeSlots) && timeSlots.map((item) => {
                          const { id, time_slot, slot_status } = item
                          return (
                            <option key={id} value={id} disabled={slot_status === 1 ? true : false}>{time_slot}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                         </div>
                    }
                    <div className="h4 font-MluvkaBold mb-2">Comments</div>
                    {
                     booking_status === "completed" ?
                    ""
                     :
                     <textarea placeholder='Add comments' onInput={handleReason} value={reason} className='w-full border border-[#ddd] rounded-xl h-[5rem] p-2 outline-none' name="" id=""></textarea>
                    }

                    {
                      
                    }
                    {
                      

<div class="relative overflow-x-auto shadow-md sm:rounded-lg">
{(notification_detail?.length > 0) &&    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                  Message
                </th>
                <th scope="col" class="px-6 py-3">
                   Status
                </th>
                <th scope="col" class="px-6 py-3">
                   Date
                </th>
            </tr>
        </thead>
        <tbody>
              {
                Array.isArray(notification_detail) && notification_detail.map((item,index) => {
                  const {notification_description,status,status_changeDateTime} = item
                  return (
                    <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                    <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    {notification_description}
                </td>
                <td class="px-6 py-4">
                {
                           status === "completed" ?
                           <span className='p-1 text-white rounded-[.5rem] block bg-[green]  text-[.6rem] w-fit'>Completed</span>
                           :
                           <span className='p-1 text-white rounded-[.5rem] block bg-[red]  text-[.6rem] w-fit'>Cancelled</span>
                          }
                </td>
                <td class="px-6 py-4">
                <div className='text-[.7rem]'>
                      <p className='leading-[1]'>{status_changeDateTime}</p>
                      </div>
                </td>
                      </tr>
                  )
                })
              }
                
        </tbody>
    </table>}
</div>

                    }
                  
                    </div>
                    <ul className='list flex justify-center gap-4 items-center py-6 px-5'>
                        {
                            booking_status !== "completed" &&
                            <li className=''>
                            <button  onClick={handleComplete} className='bg-secondary text-white uppercase font-Mluvka rounded-full py-3 px-5 w-full'>{res.isLoading ? "loading" : "Completed"}</button>
                        </li>
                        }

{
                            ( booking_status !== "cancelled" && booking_status !== "completed") &&
                       
                        <li className=''>
                            <button  onClick={handleCancellel} className='font-Mluvka w-full py-3 px-5 uppercase border-2 border-secondary rounded-full'>{res2.isLoading ? "loading" : "Cancelled"}</button>
                        </li>
}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default BookingModel
