import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import FormControl from '../../components/form/FormControl'
import swal from "sweetalert";
import profile from "../../dist/webImages/profile2.png"
import camera from "../../dist/webImages/camera.svg"
import SubmitButton from '../../components/SubmitButton';
import SkeletonHome from './SkeletonHome';
import CKEditors from '../../components/form/CKEditors';
import { MainLanguageContext } from '../../context/MainLanguageContext';
import useGet from '../../customHooks/useGet';
import { toast } from 'react-toastify';
import usePost from '../../customHooks/usePost';
const Home = () => {
  const { mainLanguage } = useContext(MainLanguageContext); 
  const [loading, setLoading] = useState(true)
  const [resget, apiMethodGet] = useGet()
  useEffect(() => {
      if (mainLanguage) {
          setLoading(true);
          apiMethodGet(`webContents/home/${mainLanguage}`);
      }
  }, [mainLanguage]);
  const [datas, setDatas] = useState({
    header_one: ""
  })

  const [imgPath, setImgPath] = useState();
  const [imgValue, setImgValue] = useState("");
  const [imgPath2, setImgPath2] = useState();
  const [imgValue2, setImgValue2] = useState("");
  const [imgPath3, setImgPath3] = useState();
  const [imgValue3, setImgValue3] = useState("");

  useEffect(() => {
    if(!resget.isLoading) {
        setLoading(false)
        setDatas(resget?.data?.data)
        setImgPath(resget?.data?.data?.header_image)
        setImgPath2(resget?.data?.data?.sec_two_image)
        setImgPath3(resget?.data?.data?.sec_four_image)
    }
   }, [resget.data])



  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      let path = (window.URL || window.webkitURL).createObjectURL(file);
      setImgPath(path);
      setImgValue(file);
    }
  }
  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files[0] && e.dataTransfer.files[0]?.type?.includes("image")) {
      let path = (window.URL || window.webkitURL).createObjectURL(e.dataTransfer.files[0]);
      setImgPath(path);
      const file = e.dataTransfer.files[0];
      setImgValue(file);
    }
    else {
      swal("Only use Image", "", "warning");
    }
  };
  const handleFileUpload2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      let path = (window.URL || window.webkitURL).createObjectURL(file);
      setImgPath2(path);
      setImgValue2(file);
    }
  }
  const handleDrop2 = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files[0] && e.dataTransfer.files[0]?.type?.includes("image")) {
      let path = (window.URL || window.webkitURL).createObjectURL(e.dataTransfer.files[0]);
      setImgPath2(path);
      const file = e.dataTransfer.files[0];
      setImgValue2(file);
    }
    else {
      swal("Only use Image", "", "warning");
    }
  };
  const handleFileUpload3 = (e) => {
    const file = e.target.files[0];
    if (file) {
      let path = (window.URL || window.webkitURL).createObjectURL(file);
      setImgPath3(path);
      setImgValue3(file);
    }
  }
  const handleDrop3 = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files[0] && e.dataTransfer.files[0]?.type?.includes("image")) {
      let path = (window.URL || window.webkitURL).createObjectURL(e.dataTransfer.files[0]);
      setImgPath3(path);
      const file = e.dataTransfer.files[0];
      setImgValue3(file);
    }
    else {
      swal("Only use Image", "", "warning");
    }
  };
  const handleheadingChange = (e) => {
    setDatas(d => ({
      ...d,
      header_one: e
      
    }));
  }
  const [res, apiMethod] = usePost()
  const requireFeild = ["meta_tag", "meta_description"];
  const handleSubmit = (values) => {
    let formdata = new FormData();
    let requireFeildSwal = {
        meta_tag: "Meta Tag",
        'meta_description': "Meta Description",
      };
      let checkerRequried = [];
      for (const item in values) {
        if (requireFeild.includes(item) && values[item] === "") {
          checkerRequried.push(requireFeildSwal[item]);
        }
        formdata.append(item,values[item] ?? "")
      }
    if (checkerRequried.length > 0) {
        swal({
          title: "Required Fields are empty! Please fill and try again",
          text: checkerRequried.join(","),
          icon: "error",
          dangerMode: true,
        });
      }
      else {
        formdata.append(`header_one`,datas?.header_one ?? "")
        formdata.append(`header_image`,imgValue ?? "")
        formdata.append(`sec_two_image`,imgValue2 ?? "")
        formdata.append(`sec_four_image`,imgValue3 ?? "")
        apiMethod(`webContents/home/${mainLanguage}`,formdata)
      }
  }

  useEffect(() => {
    if (res.data) {
      const { status, message } = res?.data
      if (status === "false") {
        toast.error(message);
      }
      else {
        toast.success(message);
      }
    }
  }, [res.data])
  if (resget.isLoading || loading || !datas) return <SkeletonHome />
  const { meta_tag,
    meta_description,
    scheme_code,
    top_right_content,
    header_two,
    sec_two_header_one,
    sec_two_header_two,
    sec_two_paragraph,
    sec_two_name,
    sec_two_details,
    sec_three_header_one,
    sec_three_header_two,
    sec_three_paragraph,
    sec_four_header_one,
    sec_four_header_two,
    sec_four_paragraph,
    sec_four_fact_one,
    sec_four_fact_one_title,
    sec_four_fact_two,
    sec_four_fact_two_title,
    sec_four_fact_three,
    sec_four_fact_three_title,
    sec_news_header_sub,
    sec_news_header_one,
  } = datas
  const initialValues = {
    meta_tag: meta_tag ?? "",
    meta_description: meta_description ?? "",
    scheme_code: scheme_code ?? "",
    top_right_content: top_right_content ?? "",
    header_two: header_two ?? "",
    sec_two_header_one: sec_two_header_one ?? "",
    sec_two_header_two: sec_two_header_two ?? "",
    sec_two_paragraph: sec_two_paragraph ?? "",
    sec_two_name: sec_two_name ?? "",
    sec_two_details: sec_two_details ?? "",
    sec_three_header_one: sec_three_header_one ?? "",
    sec_three_header_two: sec_three_header_two ?? "",
    sec_three_paragraph: sec_three_paragraph ?? "",
    sec_four_header_one: sec_four_header_one ?? "",
    sec_four_header_two: sec_four_header_two ?? "",
    sec_four_paragraph: sec_four_paragraph ?? "",
    sec_four_fact_one: sec_four_fact_one ?? "",
    sec_four_fact_one_title: sec_four_fact_one_title ?? "",
    sec_four_fact_two: sec_four_fact_two ?? "",
    sec_four_fact_two_title: sec_four_fact_two_title ?? "",
    sec_four_fact_three: sec_four_fact_three ?? "",
    sec_four_fact_three_title: sec_four_fact_three_title ?? "",
    sec_news_header_sub: sec_news_header_sub ?? "",
    sec_news_header_one: sec_news_header_one ?? "",
  }
  return (
    <div className='homePage pr-10 max-lg:pr-6'>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}  >
        <Form>
          <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
            <FormControl name="meta_tag" label={"Meta Title"} placeholder="Enter Meta Title" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
            <div className='grid grid-cols-2 gap-3 max-lg:grid-cols-1 max-lg:gap-0'>
              <FormControl name="meta_description" label={"Meta Description"} placeholder="Enter Meta Description" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
              <FormControl name="scheme_code" label={"Scheme Code"} placeholder="Scheme Code" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
            </div>
          </div>
          <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
            <div className="h4 text-[#7D8CA7] text-[1.1rem] ">Header</div>
            <div className="header grid grid-cols-3 gap-3 max-lg:grid-cols-1 max-lg:gap-0">
              <div className='header__left'>
                <label className="mb-1  mt-3 block text-[#7D8CA7] text-[.8rem]">Header image </label>
                <div className=' h-[10rem] relative' onDrop={handleDrop}   >
                  {imgPath ?
                    <img src={imgPath || profile} className='w-full h-full rounded-3xl object-cover ' alt="" />
                    :
                    <div className='w-full h-full  rounded-3xl object-cover  bg-white'></div>
                  }
                  <div className="TeamBoxinput w-[3rem] h-[3rem] bg-[#C0CCE2] grid  place-items-center rounded-full absolute mx-auto right-0 left-0 top-[50%] transform translate-y-[-50%] cursor-pointer z-1">
                    <input type="file" onChange={handleFileUpload} className='absolute inset-0 opacity-0 cursor-pointer' />
                    <img src={camera} alt="camera" className='cursor-pointer w-full p-3' />
                  </div>
                </div>
              </div>
              <div className="header__center">
                <FormControl name="top_right_content" label={"Top right content "} placeholder="Enter Top right content " className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
              </div>
              <div className="header__Right">
              <label htmlFor="Heading 1" className=" block text-[#7D8CA7] text-[.8rem]">Heading 1</label>
              <div className='CKEditorsHeight'>
               <CKEditors  data={datas?.header_one} update={handleheadingChange} />
              </div>
                <FormControl name="header_two" label={"Heading 2"} placeholder="Enter Heading 2" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
              </div>
            </div>
          </div>
          <div className='bg-[#EFF4FD] p-6 section2 rounded-3xl mb-3'>
            <div className="h4 text-[#7D8CA7] text-[1.1rem] ">Section 2</div>
            <div className="section2Main grid grid-cols-3 gap-3 max-lg:grid-cols-1">
              <div className="section2Main__left">
                <FormControl name="sec_two_header_one" label={"Heading 1"} placeholder="Enter Heading 1" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="sec_two_header_two" label={"Heading 2"} placeholder="Enter Heading 2" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
              </div>
              <div className="section2Main__center">
                <FormControl name="sec_two_paragraph" label={"Paragraph"} placeholder="Paragraph" className="outline-none w-full h-[15rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
              </div>
              <div className="section2Main__right">
                <div>
                  <label className="mb-1  mt-3 block text-[#7D8CA7] text-[.8rem]">Image</label>
                  <div className=' h-[5rem] w-[6rem] relative' onDrop={handleDrop2}   >
                    {imgPath2 ?
                      <img src={imgPath2 || profile} className='w-full h-full rounded-3xl object-cover ' alt="" />
                      :
                      <div className='w-full h-full  rounded-3xl object-cover  bg-white'></div>
                    }
                    <div className="TeamBoxinput w-[3rem] h-[3rem] bg-[#C0CCE2] grid  place-items-center rounded-full absolute mx-auto right-0 left-0 top-[50%] transform translate-y-[-50%] cursor-pointer z-1">
                      <input type="file" onChange={handleFileUpload2} className='absolute inset-0 opacity-0 cursor-pointer' />
                      <img src={camera} alt="camera" className='cursor-pointer w-full p-3' />
                    </div>
                  </div>
                </div>
                <FormControl name="sec_two_name" label={"Name"} placeholder="Enter Name" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="sec_two_details" label={"Details"} placeholder="Enter Details" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
              </div>
            </div>
          </div>
          <div className='bg-[#EFF4FD] p-6 section3 rounded-3xl mb-3'>
            <div className="h4 text-[#7D8CA7] text-[1.1rem] ">Section 3</div>
            <div className="section3Main grid grid-cols-2 gap-3 max-lg:grid-cols-1">
              <div className="section3__left">
                <FormControl name="sec_three_header_one" label={"heading 1"} placeholder="Enter heading 1" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                 <FormControl name="sec_three_header_two" label={"heading 2"} placeholder="Enter heading 2" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
              </div>
              <div className="section3__Right">
                <FormControl name="sec_three_paragraph" label={"Paragraph"} placeholder="Paragraph" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />

              </div>
            </div>
          </div>
          <div className='bg-[#EFF4FD] p-6 section4 rounded-3xl mb-3'>
            <div className="h4 text-[#7D8CA7] text-[1.1rem] ">Section 4</div>

            <div className="section4_ grid grid-cols-2 gap-3 max-lg:grid-cols-1 max-lg:gap-0">
              <div className="section4__left">
                <FormControl name="sec_four_header_one" label={"heading 1"} placeholder="Enter heading 1" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="sec_four_header_two" label={"heading 2"} placeholder="Enter heading 2" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="sec_four_paragraph" label={"Paragraph"} placeholder="Paragraph" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
              
                <div className='grid grid-cols-[3fr,7fr] gap-2 items-end'>
            <FormControl name="sec_four_fact_one" label={"Fact 1"} placeholder="" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
            <FormControl name="sec_four_fact_one_title"  placeholder="" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
            </div>
            <div className='grid grid-cols-[3fr,7fr] gap-2 items-end'>
            <FormControl name="sec_four_fact_two" label={"Fact 2"} placeholder="" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
            <FormControl name="sec_four_fact_two_title"  placeholder="" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
            </div>
            <div className='grid grid-cols-[3fr,7fr] gap-2 items-end'>
            <FormControl name="sec_four_fact_three" label={"Fact 3"} placeholder="" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
            <FormControl name="sec_four_fact_three_title"  placeholder="" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
            </div>
              </div>
              <div className="section4__Right">
                <div className=' h-[38rem] mt-8 relative max-lg:h-[15rem]' onDrop={handleDrop3}   >
                  {imgPath3 ?
                    <img src={imgPath3 || profile} className='w-full h-full rounded-3xl object-cover ' alt="" />
                    :
                    <div className='w-full h-full  rounded-3xl object-cover  bg-white'></div>
                  }
                  <div className="TeamBoxinput w-[3rem] h-[3rem] bg-[#C0CCE2] grid  place-items-center rounded-full absolute mx-auto right-0 left-0 top-[50%] transform translate-y-[-50%] cursor-pointer z-1">
                    <input type="file" onChange={handleFileUpload3} className='absolute inset-0 opacity-0 cursor-pointer' />
                    <img src={camera} alt="camera" className='cursor-pointer w-full p-3' />
                  </div>
                </div>
              </div>
            </div>
           
          </div>
          {/* <div className='bg-[#EFF4FD] p-6 section4 rounded-3xl mb-3'>
          <div className="h4 text-[#7D8CA7] text-[1.1rem] ">News</div>
          <FormControl name="sec_news_header_sub" label={"heading 1"} placeholder="Enter Sub Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
          <FormControl name="sec_news_header_one" label={"heading 2"} placeholder="Enter heading 1" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
          </div> */}
          {/* <div className='bg-[#EFF4FD] p-6 section4 rounded-3xl mb-3'>
          <div className="h4 text-[#7D8CA7] text-[1.1rem] ">News</div>
          <FormControl name="sec_news_header_sub" label={"heading 1"} placeholder="Enter Sub Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
          <FormControl name="sec_news_header_one" label={"heading 2"} placeholder="Enter heading 1" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
          </div> */}
          <SubmitButton
            props={{
              class: "btn bg-secondary text-white px-12 ml-auto uppercase mb-3   py-3 rounded-full w-100 block mt-5 submit hover:bg-primary transition-all duration-300",
              text: "Submit",
            }}
            buttonLoading={res.isLoading}
          />
        </Form>
      </Formik>
    </div>
  )
}

export default Home
