import React, { useEffect, useRef } from 'react';
import { Editor } from 'primereact/editor';

const CKEditors = ({ data, update, label }) => {
    const editorRef = useRef(null);

    const imageHandler = () => {
        // ...existing image handler code...
    };

    const clearBackground = () => {
        const quillEditor = editorRef.current.getQuill();
        const range = quillEditor.getSelection();
        if (range) {
            quillEditor.formatText(range.index, range.length, 'background', false); // Removes the background color
        }
    };

    useEffect(() => {
        const quillEditor = editorRef.current?.getQuill();
        if (quillEditor) {
            const toolbar = quillEditor.getModule('toolbar');
            toolbar.addHandler('image', imageHandler);
            // Optionally add a custom button to remove background
            const customButton = document.querySelector('.ql-remove-background');
            if (customButton) {
                customButton.addEventListener('click', clearBackground);
            }
        }
    }, []);

    const modules = {
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'font': [] }],
                [{ 'size': ['small', false, 'large', 'huge'] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'color': [ "#ceaf7f", 
                    "#000000", 
                    "#e60000", 
                    "#ff9900", 
                    "#ffff00", 
                    "#008a00", 
                    "#0066cc", 
                    "#9933ff", 
                    "#facccc", 
                    "#ffebcc", 
                    "#ffffcc", 
                    "#cce8cc", 
                    "#cce0f5", 
                    "#ebd6ff", 
                    "#bbbbbb", 
                    "#f06666", 
                    "#ffc266", 
                    "#ffff66", 
                    "#66b966", 
                    "#66a3e0", 
                    "#c285ff", 
                    "#888888", 
                    "#a10000", 
                    "#b26b00", 
                    "#b2b200", 
                    "#006100", 
                    "#0047b2", 
                    "#6b24b2", 
                    "#444444", 
                    "#5c0000", 
                    "#663d00", 
                    "#666600", 
                    "#003700", 
                    "#002966", 
                    "#3d1466"] }, { 'background': [
  "#ceaf7f", 
  "#000000", 
  "#e60000", 
  "#ff9900", 
  "#ffff00", 
  "#008a00", 
  "#0066cc", 
  "#9933ff", 
  "#facccc", 
  "#ffebcc", 
  "#ffffcc", 
  "#cce8cc", 
  "#cce0f5", 
  "#ebd6ff", 
  "#bbbbbb", 
  "#f06666", 
  "#ffc266", 
  "#ffff66", 
  "#66b966", 
  "#66a3e0", 
  "#c285ff", 
  "#888888", 
  "#a10000", 
  "#b26b00", 
  "#b2b200", 
  "#006100", 
  "#0047b2", 
  "#6b24b2", 
  "#444444", 
  "#5c0000", 
  "#663d00", 
  "#666600", 
  "#003700", 
  "#002966", 
  "#3d1466"
]
 }, { 'removeBackground': 'Remove Background' }], // Add custom remove background button
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],
                [{ 'align': [] }],
                ['link', 'image'],
                ['clean']
            ],
            handlers: {
                removeBackground: clearBackground // Custom handler for removing background
            }
        }
    };

    return (
        <div className="mt-2 bg-white">
            <Editor
                ref={editorRef}
                value={data}
                onTextChange={(e) => update(e.htmlValue)}
                style={{ height: '320px' }}
                modules={modules}
            />
        </div>
    );
}

export default CKEditors;
