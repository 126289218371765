import React, { useEffect, useState } from 'react'
import swal from "sweetalert";
import profile from "../dist/webImages/profile2.png"
import camera from "../dist/webImages/camera.svg"
import * as yup from "yup";
import { Field, Form, Formik } from 'formik';
import FormControl from '../components/form/FormControl';
import SubmitButton from '../components/SubmitButton';
import plus from '../dist/webImages/plus.svg'
import { Link } from 'react-router-dom';
import SkeletonSetting from './SkeletonSetting';
import usePost from '../customHooks/usePost';
import { toast } from 'react-toastify';
import useFetch from '../customHooks/useFetch';

const Setting = () => {
  const { loading, data } = useFetch("getProfile")
  const { loading: loading2, data: data2 } = useFetch("admin/setting/social")
  const { loading: loading3, data: data3 } = useFetch("admin/setting/get-whatsapp")
  const [imgPath, setImgPath] = useState();
  const [imgValue, setImgValue] = useState("");
  const [datas, setDatas] = useState([])

  useEffect(() => {
    if (data) {
      const { profile_image } = data?.data
      setImgPath(profile_image)
    }
  }, [data])
  useEffect(() => {
    if (data2) {
      setDatas(data2?.data)
    }
  }, [data2])




  const handleSocialLink = (value, index) => {
    setDatas((prevDatas) =>
      prevDatas.map((item, i) =>
        i === index ? { ...item, link: value } : item
      )
    );
  };

  const [res, apiMethod] = usePost()
  const handleSubmit = (values) => {
    let formdata = new FormData();

    formdata.append("profile_image", imgValue)
    for (const item in values) {
      formdata.append(item, values[item])
    }
    apiMethod("updateProfile", formdata)
  }

  const [res2, apiMethod2] = usePost()
  const handleSubmit2 = (values) => {
    let formdata = new FormData();
    for (let index = 0; index < datas.length; index++) {
      formdata.append(`social_img[${index}][image]`, datas[index].imgValue ?? "")
      formdata.append(`social_img[${index}][old_image]`, datas[index].old_image ?? "")
      formdata.append(`social_link[]`, datas[index].link)
    }
    apiMethod2("admin/setting/social", formdata)
  }



  const validationSchema3 = yup.object({
    old_password: yup.string().min(8, "Minimum 8 characters").max(20, "Maximum 20 characters").required("Required"),
    new_password: yup.string().min(8, "Minimum 8 characters").max(20, "Maximum 20 characters").required("Required")
  });
  const [res3, apiMethod3] = usePost()
  const handleSubmit3 = (values) => {
    let formdata = new FormData();
    for (const item in values) {
      formdata.append(item, values[item])
    }
    formdata.append("new_password_confirmation", values.new_password)

    apiMethod3("changePassword", formdata)

  }


  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      let path = (window.URL || window.webkitURL).createObjectURL(file);
      setImgPath(path);
      setImgValue(file)
    }
  }
  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files[0] && e.dataTransfer.files[0]?.type?.includes("image")) {
      let path = (window.URL || window.webkitURL).createObjectURL(e.dataTransfer.files[0]);
      setImgPath(path);
      const file = e.dataTransfer.files[0];
      setImgValue(file)
      // onUpload(file);
    }
    else {
      swal("Only use Image", "", "warning");
    }
  };
  const handleFileUpload2 = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      let path = (window.URL || window.webkitURL).createObjectURL(file);
      setDatas((prevData) =>
        prevData.map((item, i) =>
          i === index
            ? { ...item, image: path, imgValue: file }
            : item
        )
      );
    }
  }
  const handleDrop2 = (e, index) => {
    e.preventDefault();
    if (e.dataTransfer.files[0] && e.dataTransfer.files[0]?.type?.includes("image")) {
      let path = (window.URL || window.webkitURL).createObjectURL(e.dataTransfer.files[0]);
      const file = e.dataTransfer.files[0];
      const updatedSocial = datas.map((item, i) =>
        i === index ? { ...item, imgValue: file } : item
      );
      setDatas((prevData) =>
        prevData.map((item, i) =>
          i === index
            ? { ...item, image: path, imgValue: file }
            : item
        )
      );
    }
    else {
      swal("Only use Image", "", "warning");
    }
  };
  const handleAdd = (e) => {
    e.preventDefault()
    const newServices = {
      image: "",
      imgValue: "",
      link: ""
    };

    setDatas((prevState) => [...prevState, newServices]);
  }
  const handleDelete = () => {
    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete?",
      buttons: true,
      icon: "warning",
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {
          // setService((prevState) => ({
          //     ...prevState,
          //     section2: prevState.section2.filter(faq => faq.id !== id)
          // }));
          swal("Successfully Delete", "", "success");
        }

      });
  }

  const [whatsapp, setWhatsapp] = useState({
    "link": "",
    "file": "",
    "fileValue": "",
  })
  useEffect(() => {
 if(data3) {
  let a = data3?.data
  a["fileValue"] = data3?.data?.old_file.split("/")[data3?.data?.old_file.split("/").length -1]
  setWhatsapp(a)
 }
  }, [data3])
  
  const handleSocialLink2 = (value) => {
    setWhatsapp({ ...whatsapp, "link": value })
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      setWhatsapp({ ...whatsapp, "fileValue": file.name, file: file })
    }
  }

  const [res4, apiMethod4] = usePost()
  const handleSubmit4 = () => {
    let formdata = new FormData();
    formdata.append("link", whatsapp.link)
    formdata.append("file", whatsapp.file?.name ? whatsapp.file : "")
    apiMethod4("admin/setting/create-whatsapp", formdata)
  }

  useEffect(() => {
    if (res.data) {
      const { status, message } = res?.data
      if (status === "false") {
        let errorMessages = '';
        for (const [key, errors] of Object.entries(message)) {
          errorMessages += `<h3 className="" style="font-size:1.2rem;font-weight:600;">${key.charAt(0).toUpperCase() + key.slice(1)}</h3><ul style="list-style:disc; padding:10px;">`;
          errors.forEach(error => {
            errorMessages += `<li>${error}</li>`;
          });
          errorMessages += `</ul>`;
        }
        swal({
          title: "Error",
          content: {
            element: "div",
            attributes: {
              innerHTML: errorMessages
            }
          },
          icon: "warning"
        });
      }
      else {
        toast.success(message);
        window.location.reload()
      }
    }
    if (res2.data) {
      const { status, message } = res2?.data
      if (status === "false") {
        toast.error(message);
      }
      else {
        toast.success(message);
      }
    }
    if (res3.data) {
      const { status, message } = res3?.data
      if (status === "false") {
        toast.error(message);
      }
      else {
        toast.success(message);
      }
    }
    if (res4.data) {
      const { status, data } = res4?.data
      if (status === "false") {
        toast.error(data);
      }
      else {
        toast.success(data);
      }
    }

  }, [res.data, res2.data, res3.data,res4.data])


  if (loading || loading3) return <SkeletonSetting />;
  const { name } = data?.data
  const initialValues = {
    name: name,
  }
  const initialValues2 = {
  }
  const initialValues3 = {
    old_password: "",
    new_password: "",
  }
  return (
    <div className='Setting pr-10 max-lg:pr-6'>
      <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
        <div className="h4 text-[#7D8CA7] text-[1.1rem] ">My Profile</div>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}  >
          <Form className='flex items-start gap-5 max-lg:flex-col max-lg:gap-0'>
            <div>
              <label className="mb-1  mt-3 block text-[#7D8CA7] text-[.8rem]">Image</label>
              <div className=' h-[4rem] w-[4rem] relative' onDrop={handleDrop}   >
                {imgPath ?
                  <img src={imgPath || profile} className='w-full h-full rounded-3xl object-cover ' alt="" />
                  :
                  <div className='w-full h-full  rounded-3xl object-cover  bg-white'></div>
                }
                <div className="TeamBoxinput w-[2rem] h-[2rem] bg-[#C0CCE2] grid  place-items-center rounded-full absolute mx-auto right-0 left-0 top-[50%] transform translate-y-[-50%] cursor-pointer z-1">
                  <input type="file" onChange={handleFileUpload} className='absolute inset-0 opacity-0 cursor-pointer' />
                  <img src={camera} alt="camera" className='cursor-pointer w-full p-2' />
                </div>
              </div>
            </div>
            <div className='w-[70%]'>
              <FormControl name="name" label={"Name"} placeholder="Enter Name" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
            </div>

            <SubmitButton
              props={{
                class: "btn bg-secondary text-white px-12 ml-auto uppercase   py-3 rounded-full w-100 block mt-8 max-lg:mt-2 submit hover:bg-primary transition-all duration-300",
                text: "Update",
              }}
              buttonLoading={res.isLoading}
            />
          </Form>
        </Formik>
      </div>

      <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
        <div className='flex justify-between items-center mb-3'>
          <div className="h4 text-[#7D8CA7] text-[1.1rem] font-MluvkaBold ">Social Media Links</div>
          <Link className='bg-[#d9dcf8] py-3 px-6 rounded-full flex items-center gap-2 cursor-pointer' onClick={handleAdd} >
            <img src={plus} alt="plus" />
            <span className='font-MluvkaBold text-secondary capitalize'>Add Services</span>
          </Link>
        </div>

        <Formik initialValues={initialValues2} onSubmit={handleSubmit2}  >
          <Form>
            <div className='grid grid-cols-2 max-lg:grid-cols-1 gap-4'>
              <Field name="name" label={"Name"} placeholder="Enter Name" className="outline-none hidden w-full h-[3rem] px-5 rounded-xl" />
              {
                Array.isArray(datas) && datas.map((item, index) => {
                  const { id, image, link } = item
                  return (
                    <div className='grid relative grid-cols-[auto,1fr] gap-3  max-lg:bg-[#D4DEF1] max-lg:p-3 max-lg:rounded-lg' key={id}>
                      <div>
                        <label className="mb-1  mt-3 block text-[#7D8CA7] text-[.8rem]">Icon</label>
                        <div className=' h-[4rem] w-[4rem] relative' onDrop={(e) => handleDrop2(e, index)}   >
                          {image ?
                            <img src={image || profile} className='w-full h-full rounded-3xl object-cover ' alt="" />
                            :
                            <div className='w-full h-full  rounded-3xl object-cover  bg-white'></div>
                          }
                          <div className="TeamBoxinput w-[2rem] h-[2rem] bg-[#C0CCE2] grid  place-items-center rounded-full absolute mx-auto right-0 left-0 top-[50%] transform translate-y-[-50%] cursor-pointer z-1">
                            <input type="file" onChange={(e) => handleFileUpload2(e, index)} className='absolute inset-0 opacity-0 cursor-pointer' />
                            <img src={camera} alt="camera" className='cursor-pointer w-full p-2' />
                          </div>
                        </div>
                      </div>
                      <div className="inputBox w-full mt-3">
                        <label htmlFor="Name" className="mb-1 block text-[#7D8CA7] text-[.8rem]">Link</label>
                        <input id="Name" name="name" placeholder="Enter Link" className="outline-none w-full h-[4rem] px-5 rounded-xl" onChange={(e) => handleSocialLink(e.target.value, index)} value={link} />
                      </div>
                      {index !== 0 && <div className='closeButton absolute right-0 max-lg:right-2 max-lg:top-[.5rem] cursor-pointer ml-auto bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem] grid place-items-center rounded-[.7rem] z-10'
                        onClick={() => handleDelete()}  >
                        <svg className='w-full h-full' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863" stroke="#ED2656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>}
                    </div>
                  )
                })
              }
            </div>
            <SubmitButton
              props={{
                class: "btn bg-secondary text-white px-12 ml-auto uppercase   py-3 rounded-full w-100 block mt-8 max-lg:mt-4 submit hover:bg-primary transition-all duration-300",
                text: "Update",
              }}
              buttonLoading={res2.isLoading}
            />
          </Form>
        </Formik>
      </div>
      <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
        <label htmlFor=""><b>WhatsApp</b></label>
        <div className=' relative  max-lg:bg-[#D4DEF1] max-lg:p-3 max-lg:rounded-lg'>
          <div className="inputBox w-full mt-3">
            <label htmlFor="Name" className="mb-1 block text-[#7D8CA7] text-[.8rem]">Link</label>
            <input id="Name" name="name" placeholder="Enter Link" className="outline-none w-full h-[4rem] px-5 rounded-xl" onChange={(e) => handleSocialLink2(e.target.value)} value={whatsapp?.link} />
          </div>

        </div>
        <br />
        <div>
          <label htmlFor=""><b>Download Our Company Profile</b></label>
          <br />
          <div className='h-[4rem] relative flex items-center bg-[#fff]'>
            <span className='absolute left-[8.5rem] '>{whatsapp?.fileValue}</span>
            <input onChange={handleFile} className='outline-none text-white w-full  px-5 rounded-xl ' type="file" />
          </div>
        </div>
        <button onClick={handleSubmit4} disabled={res4.isLoading} className={"btn bg-secondary text-white px-12 ml-auto uppercase   py-3 rounded-full w-100 block mt-8 max-lg:mt-4 submit hover:bg-primary transition-all duration-300"}>{res4.isLoading ? 
        <>  <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
            </svg> <span>Loading...</span></> : "Update"}</button>
      </div>

      <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
        <div className=''>
          <div className="h4 text-[#7D8CA7] text-[1.1rem] font-MluvkaBold ">Reset Password</div>
          <Formik initialValues={initialValues3} onSubmit={handleSubmit3} validationSchema={validationSchema3} validateOnChange  >
            <Form>
              <div className='grid grid-cols-[1fr,1fr,auto] gap-3 max-lg:grid-cols-1 items-start'>
                <FormControl name="old_password" label={"Old Password"} placeholder="Enter Old Password" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="password" />
                <FormControl name="new_password" label={"New Password"} placeholder="Enter New Password" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="password" />
                <SubmitButton
                  props={{
                    class: "btn bg-secondary text-white px-12 ml-auto uppercase   py-3 rounded-full w-100 block mt-8 max-lg:mt-4 submit hover:bg-primary transition-all duration-300",
                    text: "Update",
                  }}
                  buttonLoading={res3.isLoading}
                />
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default Setting