import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import FormControl from '../../components/form/FormControl'
import SubmitButton from '../../components/SubmitButton'
import SkeletonContactUs from './SkeletonContactUs'
import { MainLanguageContext } from '../../context/MainLanguageContext'
import useGet from '../../customHooks/useGet'
import usePost from '../../customHooks/usePost'
import swal from "sweetalert";
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import plus from '../../dist/webImages/plus.svg'

const ContactUs = () => {
  const { mainLanguage } = useContext(MainLanguageContext);
  const [loading, setLoading] = useState(true)
  const [datas, setDatas] = useState({})
  const [datas2, setDatas2] = useState([
    {
      branch_heading:"",
      branch_location:"",
      branch_phone:"",
      branch_email:"",
      branch_map_url:"",
    }
  ])
  const [resget, apiMethodGet] = useGet()
  useEffect(() => {
    if (mainLanguage) {
      setLoading(true);
      apiMethodGet(`webContents/contact/${mainLanguage}`);
    }
  }, [mainLanguage]);

  useEffect(() => {
    if (!resget.isLoading) {
      setLoading(false)
      setDatas(resget?.data?.data)
      setDatas2(resget?.data?.data?.branches)
    }
  }, [resget.data])


  const [res, apiMethod] = usePost()
  const requireFeild = ["meta_tag","meta_description","sec_one_heading","sec_two_heading"]
  const handleSubmit = (values) => {
    let formdata = new FormData();
    let requireFeildSwal = {
      meta_tag:"Meta Title",
      meta_description:"Meta Description",
      sec_one_heading: "sec one heading",
      sec_two_heading: "sec two heading",
    };
    let checkerRequried = [];
    for (const item in values) {
      if (requireFeild.includes(item) && values[item] === "") {
        checkerRequried.push(requireFeildSwal[item]);
      }
      formdata.append(`translation[${item}]`, values[item]);
    }
    
    if (checkerRequried.length > 0) {
      swal({
          title: "Required Fields are empty! Please fill and try again",
          text: checkerRequried.join(","),
          icon: "error",
          dangerMode: true,
      });
  }

  else {
    datas2.map((branch, index) => 
      Object.entries(branch).map(([key, value]) =>
        formdata.append(`translation[branches][${index}][${key}]`, value)
      )
    );
    apiMethod(`webContents/contact/${mainLanguage}`, formdata)
  }
  }

  useEffect(() => {
    if (res.data) {
      const { status, message } = res?.data
      if (status === "false") {
        toast.error(message);
      }
      else {
        toast.success(message);
      }
    }
  }, [res.data])

  const handleContactAdd = () => {
    const newServices = {
      branch_heading:"",
      branch_location:"",
      branch_phone:"",
      branch_email:"",
      branch_map_url:"",
  };

  setDatas2(prevState => [...prevState, newServices]);
  }
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setDatas2(prevService =>
      prevService.map((item, index2) =>
        index2 === index ? { ...item, [name]: value } : item
      )
    );
  };
  const handleDelete = (index) => {
    swal({
      title: "Are you sure?",
      text: "you want to remove",
      buttons: true,
      icon: "warning",
      dangerMode: true,
  })
      .then(willDelete => {
          if (willDelete) {
            setDatas2(prevState =>  prevState.filter((contact,i) => i !== index));
              swal("Successfully Delete", "", "success");
          }

      });
  }


  if (resget.isLoading) return <SkeletonContactUs />
const dd = resget?.data?.data
  const initialValues = {
    meta_tag: dd?.meta_tag,
    meta_description: dd?.meta_description,
    schema_code: dd?.schema_code,
    sec_one_heading: dd?.sec_one_heading,
    sec_two_heading: dd?.sec_two_heading,
  }
  return (
    <div className='contactUsPage pr-10 max-lg:pr-6'>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} >
        <Form>
          <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
            <FormControl name="meta_tag" label={"Meta Title"} placeholder="Enter Meta Title" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
            <div className='grid grid-cols-2 gap-3 max-lg:grid-cols-1 max-lg:gap-0'>
              <FormControl name="meta_description" label={"Meta Description"} placeholder="Enter Meta Description" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
              <FormControl name="schema_code" label={"Scheme Code"} placeholder="Scheme Code" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
            </div>
          </div>
          <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
            <div className="h4 text-[#7D8CA7] text-[1.1rem] ">Section 1</div>
            <FormControl name="sec_one_heading" label={"Heading"} placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
          </div>
          <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
            <div className="h4 text-[#7D8CA7] text-[1.1rem] ">Section 2</div>
            <FormControl name="sec_two_heading" label={"Heading"} placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
           
           
           <div className='contactMain mt-4'>
                  <Link className='bg-[#d9dcf8] py-3 px-6 rounded-full flex items-center gap-2 cursor-pointer w-fit ml-auto' onClick={handleContactAdd} >
                                <img src={plus} alt="plus" />
                                <span className='font-MluvkaBold text-secondary capitalize'>Add new</span>
                            </Link>
                            <div className="section2Main mt-3 grid grid-cols-3 gap-3 max-lg:grid-cols-1">
                              {Array.isArray(datas2) && datas2.map((item,index) => {
                                const {branch_heading,branch_location,branch_phone,branch_email,branch_map_url} = item
                                return (
                                  <div className="section2MainBox relative bg-[#D4DEF1] p-4 rounded-2xl" key={index}>
                                      <div className='closeButton cursor-pointer absolute right-[-.5rem] top-[-.5rem] ml-auto bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem] grid place-items-center rounded-[.7rem] z-10'
                                                onClick={() => handleDelete(index)}  >
                                                <svg className='w-full h-full' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863" stroke="#ED2656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                  <div className="inputBox w-full mt-3">
                                    <label for="Heading" className="mb-1 block text-[#7D8CA7] text-[.8rem]">Heading</label>
                                    <input name='branch_heading' onChange={(e) => handleInputChange(e,index)} value={branch_heading} placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" />
                                  </div>
                                  <div className="inputBox w-full mt-3">
                                    <label for="Location" className="mb-1 block text-[#7D8CA7] text-[.8rem]">Location</label>
                                    <textarea name='branch_location' onChange={(e) => handleInputChange(e,index)} value={branch_location} placeholder="Enter Location"
                                      className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none"></textarea>
                                  </div>
                                  <div className="inputBox w-full mt-3">
                                    <label for="Phone" className="mb-1 block text-[#7D8CA7] text-[.8rem]">Phone</label>
                                    <input type="phone" name='branch_phone' onChange={(e) => handleInputChange(e,index)} value={branch_phone} placeholder="Enter Phone" className="outline-none w-full h-[3rem] px-5 rounded-xl" />
                                  </div>
                                  <div className="inputBox w-full mt-3">
                                    <label for="Email" className="mb-1 block text-[#7D8CA7] text-[.8rem]">Email</label>
                                    <input type="email" name='branch_email' onChange={(e) => handleInputChange(e,index)} value={branch_email} placeholder="Enter Email" className="outline-none w-full h-[3rem] px-5 rounded-xl" />
                                  </div>
                                  <div className="inputBox w-full mt-3">
                                    <label for="Location URL" className="mb-1 block text-[#7D8CA7] text-[.8rem]">Location URL</label>
                                    <textarea name='branch_map_url' onChange={(e) => handleInputChange(e,index)} value={branch_map_url} placeholder="Enter Location URL"
                                      className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2"></textarea>
                                  </div>
                                </div>
                                )
                              })}
                            </div>


            </div>

          </div>
          <SubmitButton
            props={{
              class: "btn bg-secondary text-white px-12 ml-auto uppercase mb-3   py-3 rounded-full w-100 block mt-5 submit hover:bg-primary transition-all duration-300",
              text: "Submit",
            }}
            buttonLoading={res.isLoading}

          
          />
        </Form>
      </Formik>

    </div>
  )
}

export default ContactUs
