import React, { useContext, useEffect, useState } from 'react'
import plus from '../dist/webImages/plus.svg'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import FormControl from '../components/form/FormControl';
import swal from "sweetalert";
import profile from "../dist/webImages/profile2.png"
import camera from "../dist/webImages/camera.svg"
import SubmitButton from '../components/SubmitButton';
import SkeletonCreateEditServices from './SkeletonCreateEditServices';
import back from "../dist/webImages/back.svg";
import { MainLanguageContext } from '../context/MainLanguageContext';
import useGet from '../customHooks/useGet';
import usePost from '../customHooks/usePost';
import { toast } from 'react-toastify';

const EditServices = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const { mainLanguage } = useContext(MainLanguageContext); 
    const [loading, setLoading] = useState(true)
    const [category, setCategory] = useState("")
    const [resget2, apiMethodGet2] = useGet()
    const [resget, apiMethodGet] = useGet()
    useEffect(() => {
        if (mainLanguage) {
            apiMethodGet2(`servicescategory/service_categories/${mainLanguage}`);
        }
      }, [mainLanguage]);
    const [datas, setDatas] = useState({
        "sec_two":[],
        "sec_three":[],
        "sec_four":[],
        "faq": [],
        "laws": [],
    })
    useEffect(() => {
        if (mainLanguage) {
          setLoading(true);
          apiMethodGet(`services/singleDetail/${id}/${mainLanguage}`);
        }
      }, [mainLanguage]);
      const [imgPath, setImgPath] = useState();
      const [imgValue, setImgValue] = useState();
      useEffect(() => {
        if(!resget.isLoading) {
            setLoading(false)
            setDatas(resget?.data?.data);
           setImgPath(resget?.data?.data?.sec_one_image)
           setCategory(resget?.data?.data?.category)
        }
       
       }, [resget.data])
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            let path = (window.URL || window.webkitURL).createObjectURL(file);
            setImgPath(path);
            setImgValue(file);
        }
    }
    const handleDrop = (e) => {
        e.preventDefault();
        if (e.dataTransfer.files[0] && e.dataTransfer.files[0]?.type?.includes("image")) {
            let path = (window.URL || window.webkitURL).createObjectURL(e.dataTransfer.files[0]);
            setImgPath(path);
            const file = e.dataTransfer.files[0];
            setImgValue(file);
        }
        else {
            swal("Only use Image", "", "warning");
        }
    };
    const handleFileUpload2 = (e, section, index) => {
        const file = e.target.files[0];
        if (file) {
            let path = (window.URL || window.webkitURL).createObjectURL(file);
            setDatas(prevService => ({
                ...prevService,
                [section]: prevService[section].map((item,i) =>
                    i === index ? { ...item, "image": path, "imgValue": file } : item
                )
            }));
        }
    }
    const handleDrop2 = (e, section, index) => {
        if (e.dataTransfer.files[0] && e.dataTransfer.files[0]?.type?.includes("image")) {
            let path = (window.URL || window.webkitURL).createObjectURL(e.dataTransfer.files[0]);
            const file = e.dataTransfer.files[0];
            setDatas(prevService => ({
                ...prevService,
                [section]: prevService[section].map((item,i) =>
                    i === index ? { ...item, image: path, imgValue: file } : item
                )
            }));
        } else {
            swal("Only use Image", "", "warning");
        }
    };

    const handleFileUpload3 = (e,section, index) => {
        const file = e.target.files[0];
        if (file) {
            let path = (window.URL || window.webkitURL).createObjectURL(file);
            setDatas(prevService => ({
                ...prevService,
                [section]: prevService[section].map((item,i) =>
                    i === index ? { ...item, image: path, imgValue: file } : item
                )
            }));
        }
    }
    const handleDrop3 = (e,section, index) => {
        e.preventDefault(); // Prevent default behavior (Prevent file from being opened)

        if (e.dataTransfer.files[0] && e.dataTransfer.files[0]?.type?.includes("image")) {
            const path = (window.URL || window.webkitURL).createObjectURL(e.dataTransfer.files[0]);
            const file = e.dataTransfer.files[0];

            setDatas(prevService => ({
                ...prevService,
                [section]: prevService[section].map((item,i) =>
                    i === index ? { ...item, image: path, imgValue: file } : item
                )
            }));
        } else {
            swal("Only use Image", "", "warning");
        }
    };


    const handleInputChange = (e, section, index) => {
        const { name, value } = e.target;
        setDatas(prevService => ({
            ...prevService,
            [section]: prevService[section].map((item,i) =>
                index === i ? { ...item, [name]: value } : item
            )
        }));
    }


    const handleInputChange3 = (e,section, index) => {
        const { name, value } = e.target;
        setDatas(prevService => ({
            ...prevService,
            [section]: prevService[section].map((item,i) =>
                index === i ? { ...item, [name]: value } : item
            )
        }));
    };

    const handlePlus = () => {
        const newServices = {
            heading: "",
            description: "",
            image: "",
        };

        setDatas((prevState) => ({
            ...prevState,
            sec_two: [...prevState.sec_two, newServices]
        }));
    }
    const handlePlus2 = () => {
        const newServices = {
            question: "",
            answer: "",
            "image": "",
            "imgValue": "",
        };

        setDatas((prevState) => ({
            ...prevState,
            sec_three: [...prevState.sec_three, newServices]
        }));
    }
    const handlePlus4 = () => {
        const newIcon = {
            title: "",
            image: "",
            imgValue: "",
        };

        setDatas(prevState => ({
            ...prevState,
            sec_four: [...prevState.sec_four, newIcon]
        }));
    };
    const [res2, apiMethod2] = usePost()
    const handleDelete = (index) => {
        let formdata = new FormData();
          formdata.append(`service_id`, id);
          formdata.append(`section`, "sec_two");
          formdata.append(`index`, index);
        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to delete?",
            buttons: true,
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    setDatas((prevState) => ({
                        ...prevState,
                        sec_two: prevState.sec_two.filter((item,i) => i !== index)
                    }));
                    apiMethod2("service_sections/remove-inner-object",formdata)
                    swal("Successfully Delete", "", "success");
                }

            });
    }
    useEffect(() => {
        if (res2.data) {
            const { status, message } = res2?.data
            if (status === "false") {
              toast.error(message);
            }
            else {
              toast.success(message);
            }
          }
    }, [res2.data])
    
    const handleDelete3 = (index) => {
        let formdata = new FormData();
        formdata.append(`service_id`, id);
        formdata.append(`section`, "sec_three");
        formdata.append(`index`, index);
        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to delete?",
            buttons: true,
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    setDatas((prevState) => ({
                        ...prevState,
                        sec_three: prevState.sec_three.filter((item,i) => i !== index)
                    }));
                    apiMethod2("service_sections/remove-inner-object",formdata)
                    swal("Successfully Delete", "", "success");
                }

            });
    }
    const handleDelete4 = (index) => {
        let formdata = new FormData();
        formdata.append(`service_id`, id);
        formdata.append(`section`, "sec_four");
        formdata.append(`index`, index);
        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to delete?",
            buttons: true,
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    setDatas((prevState) => ({
                        ...prevState,
                        sec_four: prevState.sec_four.filter((item,i) => i !== index)
                    }));
                    apiMethod2("service_sections/remove-inner-object",formdata)
                    swal("Successfully Delete", "", "success");
                }

            });
    }

    const handleChangeFaq = (index, value, type) => {
        setDatas((prevState) => {
            const updatedsec_two = prevState.faqs.map((item, i) =>
                i === index ? { ...item, [type]: value } : item
            );
            return { ...prevState, faqs: updatedsec_two };
        });
    };
    const handlePlusFaq = () => {
        const newFaq = {
            id: datas?.faqs.length + 1,
            question: "",
            answer: "",
        };

        setDatas((prevState) => ({
            ...prevState,
            faqs: [...prevState.faqs, newFaq]
        }));
    };

    const handleDeleteFaq = (index) => {
        let formdata = new FormData();
        formdata.append(`service_id`, id);
        formdata.append(`section`, "faqs");
        formdata.append(`index`, index);
        swal({
            title: "Are you sure?",
            text: " you want to remove?",
            buttons: true,
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    setDatas((prevState) => ({
                        ...prevState,
                        faqs: prevState.faqs.filter((faq, i) => i !== index)
                    }));
                    apiMethod2("service_sections/remove-inner-object",formdata)
                    swal("Successfully Delete", "", "success");
                }

            });
    }


    const handleChangeLaws = (index, value, type) => {
        setDatas((prevState) => {
            const updatedsec_two = prevState.laws.map((item, i) =>
                i === index ? { ...item, [type]: value } : item
            );
            return { ...prevState, laws: updatedsec_two };
        });
        console.log(datas)
    };
    const handlePlusLaws = () => {
        const newFaq = {
            id: datas?.laws?.length + 1,
            question: "",
            answer: "",
        };

        setDatas((prevState) => ({
            ...prevState,
            laws: [...prevState.laws, newFaq]
        }));
    };

    const handleDeleteLaws = (index) => {
        let formdata = new FormData();
        formdata.append(`service_id`, id);
        formdata.append(`section`, "laws");
        formdata.append(`index`, index);
        swal({
            title: "Are you sure?",
            text: " you want to remove?",
            buttons: true,
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    setDatas((prevState) => ({
                        ...prevState,
                        laws: prevState.laws.filter((faq, i) => i !== index)
                    }));
                    apiMethod2("service_sections/remove-inner-object",formdata)
                    swal("Successfully Delete", "", "success");
                }

            });
        }
    

    const [res, apiMethod] = usePost()
    const requireFeild = ["meta_tag","meta_description","sec_one_heading_one"];


 const handleSubmit = (values) => {
    let formdata = new FormData();
    let requireFeildSwal = {
        meta_tag:"Meta Title",
        meta_description:"Meta Description",
        sec_one_heading_one:"Section 1 Heading",
      };
      let checkerRequried = [];
    for (const item in values) {
        if (requireFeild.includes(item) && values[item] === "") {
          checkerRequried.push(requireFeildSwal[item]);
        }
        formdata.append(`translation[${item}]`, values[item]);
    }

if(!imgPath) {
    checkerRequried.push("Section 1 Image");
}


    
    
    if (checkerRequried.length > 0) {
        swal({
            title: "Required Fields are empty! Please fill and try again",
            text: checkerRequried.join(","),
            icon: "error",
            dangerMode: true,
        });
    }
    
    else {
        formdata.append(`service_category_id`, category ?? "");
        formdata.append(`sec_one_image`, imgValue ?? "");

        if( datas?.sec_two.length > 0) {
            for (let index = 0; index < datas?.sec_two.length; index++) {
                formdata.append(`translation[sec_two][${index}][heading]`,  datas?.sec_two[index]?.heading ?? "");
                formdata.append(`translation[sec_two][${index}][description]`,  datas?.sec_two[index]?.description ?? "");
                formdata.append(`translation[sec_two][${index}][image]`, datas?.sec_two[index]?.imgValue ?? "");
                
            }
        }
        if( datas?.sec_three.length > 0) {
            for (let index = 0; index < datas?.sec_three.length; index++) {
                formdata.append(`translation[sec_three][${index}][heading_one]`, datas?.sec_three[index]?.heading_one ?? "");
                formdata.append(`translation[sec_three][${index}][heading_two]`, datas?.sec_three[index]?.heading_two ?? "");
                formdata.append(`translation[sec_three][${index}][description]`, datas?.sec_three[index]?.description ?? "");
                formdata.append(`translation[sec_three][${index}][image]`, datas?.sec_three[index]?.imgValue ?? "");
                
            }
        }
        if( datas?.sec_four.length > 0) {
            for (let index = 0; index < datas?.sec_four.length; index++) {
                formdata.append(`translation[sec_four][${index}][image]`,  datas?.sec_four[index]?.imgValue ?? "");
                formdata.append(`translation[sec_four][${index}][title]`, datas?.sec_four[index]?.title ?? "");
                
            }
        }
        if (datas?.faqs.length > 0) {
            for (let index = 0; index < datas?.faqs.length; index++) {
                formdata.append(`translation[faqs][${index}][question]`,datas?.faqs[index]?.question ?? "");
                formdata.append(`translation[faqs][${index}][answer]`,datas?.faqs[index]?.answer ?? "");

            }
        }
        if (datas?.laws.length > 0) {
            for (let index = 0; index < datas?.laws.length; index++) {
                formdata.append(`translation[laws][${index}][question]`,datas?.laws[index]?.question ?? "");
                formdata.append(`translation[laws][${index}][answer]`,datas?.laws[index]?.answer ?? "");

            }
        }
      apiMethod(`services/${id}/${mainLanguage}`, formdata)
    }
 }

 useEffect(() => {
    if (res.data) {
      const { status, message } = res?.data
      if (status === "false") {
        toast.error(message);
      }
      else {
        navigate("/services")
        toast.success(message);
      }
    }
  }, [res.data])


  const handleCategory = (e) => {
      setCategory(e.target.value)
  }

    if (resget.isLoading) return <SkeletonCreateEditServices heading={"Edit Services"} />

    const initialValues = {
        meta_tag: resget?.data?.data?.meta_tag ?? "",
        meta_description: resget?.data?.data?.meta_description ?? "",
        schema_code: resget?.data?.data?.schema_code ?? "",
        sec_one_heading_one: resget?.data?.data?.sec_one_heading_one ?? "",
        sec_one_heading_two: resget?.data?.data?.sec_one_heading_two ?? "",
        sec_one_description: resget?.data?.data?.sec_one_description ?? "",
        sec_four_heading: resget?.data?.data?.sec_four_heading ?? "",
        sec_four_description: resget?.data?.data?.sec_four_description ?? "",
        faqs_short_heading: resget?.data?.data?.faqs_short_heading ?? "",
        faqs_heading: resget?.data?.data?.faqs_heading ?? "",
        laws_short_heading: resget?.data?.data?.laws_short_heading ?? "",
        laws_heading: resget?.data?.data?.laws_heading ?? "",
    }
    return (
        <div className='services pr-10 max-lg:pr-6'>
           <Link to={"/services"} className="back flex items-center mb-5 gap-2">
            <img src={back} className='w-[2rem]' alt="" />
            <span className='text-[1.4rem] font-MluvkaBold'>Edit Services</span>
            </Link>
            <div className="servicesBottom">
                <Formik initialValues={initialValues} onSubmit={handleSubmit}   >
                    <Form>
                    <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-8'>
                            <FormControl name="meta_tag" label={"Meta Title"} placeholder="Enter Meta Title" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                            <div className='grid grid-cols-2 gap-3 max-lg:grid-cols-1 max-lg:gap-1'>
                                <FormControl name="meta_description" label={"Meta Description"} placeholder="Enter Meta Description" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                                <FormControl name="schema_code" label={"Scheme Code"} placeholder="Scheme Code" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                            </div>
                        </div>
                        <h2 className='mb-2 font-MluvkaBold text-[1.5rem]'>Select Category</h2>
                        <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
                            <select value={category} onInput={handleCategory} className='w-full  border border-[#ddd]  h-[2.8rem] px-4 rounded-sm  outline-0 capitalize appearance-none max-lg:text-[.9rem]' name="" id="">
                                <option value={""} >select category</option>
                                {!resget2.isLoading &&
                                Array.isArray(resget2?.data?.data) && resget2?.data?.data.map((item) => {
                                    const {id,category_title} = item
                                    return (
                                        <option key={id} value={id}>{category_title}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <h2 className='mb-2 font-MluvkaBold text-[1.5rem]'>Service 01 Title will be here</h2>
                        <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
                            <div className="h4 text-[#7D8CA7] text-[1.1rem] ">Section 1</div>
                            <div className="grid grid-cols-[7fr,3fr] gap-4 max-lg:grid-cols-1">
                            <div className='section1left'>
                                    <FormControl name="sec_one_heading_one" label={"Heading"} placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                                    <FormControl name="sec_one_heading_two" label={"Heading 2"} placeholder="Enter Heading 2" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                                    <FormControl name="sec_one_description" label={"Description"} placeholder="Description" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                                </div>
                                <div className="section1right">
                                    <div className=' h-[23rem] relative' onDrop={handleDrop}   >
                                        {imgPath ?
                                            <img src={imgPath || profile} className='w-full h-full rounded-3xl object-cover ' alt="" />
                                            :
                                            <div className='w-full h-full  rounded-3xl object-cover  bg-white'></div>
                                        }
                                        <div className="TeamBoxinput w-[3rem] h-[3rem] bg-[#C0CCE2] grid  place-items-center rounded-full absolute mx-auto right-0 left-0 top-[50%] transform translate-y-[-50%] cursor-pointer z-1">
                                            <input type="file" onChange={handleFileUpload} className='absolute inset-0 opacity-0 cursor-pointer' />
                                            <img src={camera} alt="camera" className='cursor-pointer w-full p-3' />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* close  */}
                        <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
                            <div className="h4 text-[#7D8CA7] text-[1.1rem] ">Section 2</div>
                            <div className='sec_twoMain grid grid-cols-3 mt-3 gap-2 max-lg:grid-cols-1'>
                                {
                                  Array.isArray(datas?.sec_two) && datas?.sec_two.map((item,index) => {
                                        const {  heading, description,image } = item;
                                        return (
                                            <div key={index} className="sec_twoMainBox relative bg-[#D4DEF1] rounded-2xl p-3">
                                                <div
                                                    className='closeButton cursor-pointer ml-auto bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem] grid place-items-center rounded-[.7rem] z-10'
                                                    onClick={() => handleDelete(index)}
                                                >
                                                    <svg className='w-full h-full' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863" stroke="#ED2656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                                <div className="inputBox w-full mt-3">
                                                    <label className="mb-1 block text-[#7D8CA7] text-[.8rem]">Heading</label>
                                                    <input
                                                        name="heading"
                                                        placeholder="Enter Heading"
                                                        className="outline-none w-full h-[3rem] px-5 rounded-xl"
                                                        value={heading}
                                                        onChange={(e) => handleInputChange(e, "sec_two", index)}
                                                    />
                                                </div>
                                                <div className="inputBox w-full mt-3">
                                                    <label className="mb-1 block text-[#7D8CA7] text-[.8rem]">Paragraph</label>
                                                    <textarea
                                                        name="description"
                                                        placeholder="Paragraph"
                                                        className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none"
                                                        value={description}
                                                        onChange={(e) => handleInputChange(e, "sec_two", index)}
                                                    ></textarea>
                                                </div>`
                                                
                                                <div className=' h-[10rem] relative' onDrop={(e) => handleDrop2(e, "sec_two", index)}   >
                                                {image ?
                                                    <img src={image || profile} className='w-full h-full rounded-2xl object-cover ' alt="" />
                                                    :
                                                    <div className='w-full h-full  rounded-2xl object-cover  bg-white'></div>
                                                }
                                                <div className="TeamBoxinput w-[3rem] h-[3rem] bg-[#C0CCE2] grid  place-items-center rounded-full absolute mx-auto right-0 left-0 top-[50%] transform translate-y-[-50%] cursor-pointer z-1">
                                                    <input type="file" onChange={(e) => handleFileUpload2(e, "sec_two", index)} className='absolute inset-0 opacity-0 cursor-pointer' />
                                                    <img src={camera} alt="camera" className='cursor-pointer w-full p-3' />
                                                </div>
                                            </div>`

                                            </div>
                                        );
                                    })
                                }


                            </div>
                            <div className='bg-[#d9dcf8] py-3 mt-4 w-fit px-6 rounded-full flex items-center gap-2 cursor-pointer' onClick={handlePlus}>
                                <img src={plus} alt="plus" />
                                <span className='font-MluvkaBold text-secondary capitalize'>Add new</span>
                            </div>
                        </div>
                        <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
                            <div className="h4 text-[#7D8CA7] text-[1.1rem] mb-2 ">Section 3</div>
                            <div className="sec_threeMain grid grid-cols-3 gap-3 max-lg:grid-cols-1">
                                {Array.isArray(datas?.sec_three) && datas?.sec_three.map((item,index) => {
                                    const {  heading_one, heading_two, description, image } = item;
                                    return (
                                        <div className="sec_threeMainBox relative bg-[#D4DEF1] rounded-2xl p-3" key={index}>
                                            <div className='closeButton cursor-pointer ml-auto bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem] grid place-items-center rounded-[.7rem] z-10'
                                                onClick={() => handleDelete3(index)}  >
                                                <svg className='w-full h-full' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863" stroke="#ED2656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                            <div className="inputBox w-full mt-3">
                                                <label className="mb-1 block text-[#7D8CA7] text-[.8rem]">Heading</label>
                                                <input
                                                    name="heading_one"
                                                    placeholder="Enter Heading"
                                                    className="outline-none w-full h-[3rem] px-5 rounded-xl"
                                                    value={heading_one}
                                                    onChange={(e) => handleInputChange(e, "sec_three", index)}
                                                />
                                            </div>
                                            <div className="inputBox w-full mt-3">
                                                <label className="mb-1 block text-[#7D8CA7] text-[.8rem]">Sub Heading</label>
                                                <input
                                                    name="heading_two"
                                                    placeholder="Enter Sub Heading"
                                                    className="outline-none w-full h-[3rem] px-5 rounded-xl"
                                                    value={heading_two}
                                                    onChange={(e) => handleInputChange(e, "sec_three", index)}
                                                />
                                            </div>
                                            <div className="inputBox w-full mt-3">
                                                <label htmlFor={`paragraph-3`} className="mb-1 block text-[#7D8CA7] text-[.8rem]">Paragraph</label>
                                                <textarea
                                                    name="description"
                                                    placeholder="Paragraph"
                                                    className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none"
                                                    value={description}
                                                    onChange={(e) => handleInputChange(e, "sec_three", index)}
                                                ></textarea>
                                            </div>
                                            <div className=' h-[10rem] relative' onDrop={(e) => handleDrop2(e, "sec_three", index)}   >
                                                {image ?
                                                    <img src={image || profile} className='w-full h-full rounded-2xl object-cover ' alt="" />
                                                    :
                                                    <div className='w-full h-full  rounded-2xl object-cover  bg-white'></div>
                                                }
                                                <div className="TeamBoxinput w-[3rem] h-[3rem] bg-[#C0CCE2] grid  place-items-center rounded-full absolute mx-auto right-0 left-0 top-[50%] transform translate-y-[-50%] cursor-pointer z-1">
                                                    <input type="file" onChange={(e) => handleFileUpload2(e, "sec_three", index)} className='absolute inset-0 opacity-0 cursor-pointer' />
                                                    <img src={camera} alt="camera" className='cursor-pointer w-full p-3' />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                            <div className='bg-[#d9dcf8] py-3 mt-4 w-fit px-6 rounded-full flex items-center gap-2 cursor-pointer' onClick={handlePlus2}>
                                <img src={plus} alt="plus" />
                                <span className='font-MluvkaBold text-secondary capitalize'>Add new</span>
                            </div>
                        </div>
                        <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3 sec_four'>
                            <div className="h4 text-[#7D8CA7] text-[1.1rem] mb-2 ">Section 4</div>
                            <FormControl name="sec_four_heading" label={"Heading"} placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                            <FormControl name="sec_four_description" label={"Paragraph"} placeholder="Enter Paragraph" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                            <div className="sec_fourM">
                                <div className="h4 text-[#7D8CA7] text-[1.1rem] mb-2 mt-4 ">Icons</div>
                                <div className="sec_fourMa bg-[#D4DEF1] p-3 rounded-2xl">
                                    <div className=" grid grid-cols-5 gap-3 max-lg:grid-cols-2">
                                        {
                                          Array.isArray(datas?.sec_four)  && datas?.sec_four.map((item,index) => {
                                                const {  image, title } = item;
                                                return (
                                                    <div className="sec_fourMainBox relative" key={index}>
                                                        <div className='closeButton cursor-pointer absolute right-[-.5rem] top-[-.5rem] ml-auto bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem] grid place-items-center rounded-[.7rem] z-10'
                                                            onClick={() => handleDelete4(index)}  >
                                                            <svg className='w-full h-full' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863" stroke="#ED2656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </div>
                                                        <div className=' h-[10rem] relative' onDrop={(e) => handleDrop3(e,"sec_four", index)}   >
                                                            {image ?
                                                                <img src={image || profile} className='w-full h-full rounded-2xl object-cover ' alt="" />
                                                                :
                                                                <div className='w-full h-full  rounded-2xl object-cover  bg-white'></div>
                                                            }
                                                            <div className="TeamBoxinput w-[3rem] h-[3rem] bg-[#C0CCE2] grid  place-items-center rounded-full absolute mx-auto right-0 left-0 top-[50%] transform translate-y-[-50%] cursor-pointer z-1">
                                                                <input type="file" onChange={(e) => handleFileUpload3(e,"sec_four", index)} className='absolute inset-0 opacity-0 cursor-pointer' />
                                                                <img src={camera} alt="camera" className='cursor-pointer w-full p-3' />
                                                            </div>
                                                        </div>
                                                        <div className="inputBox w-full mt-3">
                                                            <input
                                                                name="title"
                                                                placeholder="Enter Title"
                                                                className="outline-none w-full h-[3rem] px-5 rounded-xl"
                                                                value={title}
                                                                onChange={(e) => handleInputChange3(e,"sec_four", index)}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                    <div className='bg-[#b4bae9] py-3 mt-4 w-fit px-6 rounded-full flex items-center gap-2 cursor-pointer' onClick={handlePlus4}>
                                        <img src={plus} alt="plus" />
                                        <span className='font-MluvkaBold text-secondary capitalize'>Add new</span>
                                    </div>
                                </div>
                            </div>
                            
                            </div>

<div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3 sec_faq'>
                            <div className="h4 text-[#7D8CA7] text-[1.1rem] mb-2 ">Faq</div>
                            <div className='flex items-end gap-4 max-lg:flex-col max-lg:gap-0'>
                                <FormControl name="faqs_short_heading" label={"Short Heading"} placeholder="Enter Short Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                                <FormControl name="faqs_heading" label={"Heading"} placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                            </div>
                            <br />
                            <div className='grid grid-cols-2 gap-4'>
                            {
                                Array.isArray(datas?.faqs) && datas?.faqs.map((item, index) => {
                                    const { question, answer } = item
                                    return (
                                        <div className='bg-[#D4DEF1] p-5 rounded-3xl max-lg:p-3' key={index}>
                                            <div className='mb-3'>
                                                <label htmlFor="" className='mb-2 text-[#7D8CA7] text-[.8rem] items-center flex justify-between'>
                                                    <span>Question</span>
                                                    {<div className='closeButton cursor-pointer  bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem]  grid place-items-center rounded-[.7rem] z-10' onClick={() => handleDeleteFaq(index)}>
                                                        <svg className='w-full h-full' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863" stroke="#ED2656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>}
                                                </label>
                                                <input type="text" value={question} className='outline-none w-full h-[3rem] px-5 rounded-xl' placeholder='Question' onChange={(e) => handleChangeFaq(index, e.target.value, "question")} />
                                            </div>
                                            <div className=''>
                                                <label htmlFor="" className='mb-1 text-[#7D8CA7] text-[.8rem] block'><span>Answer</span> </label>
                                                <textarea name="" value={answer} className='outline-none w-full h-[10rem] border border-[#CFD5E2] px-5 py-3 resize-none rounded-2xl' placeholder='Answer' onChange={(e) => handleChangeFaq(index, e.target.value, "answer")}></textarea>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            </div>
                    

                            <div className='bg-[#d9dcf8] py-3 mt-4 w-fit px-6 rounded-full flex items-center gap-2 cursor-pointer' onClick={handlePlusFaq}>
                                <img src={plus} alt="plus" />
                                <span className='font-MluvkaBold text-secondary capitalize'>Add new</span>
                            </div>
                        </div>
                        <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3 sec_faq'>
                            <div className="h4 text-[#7D8CA7] text-[1.1rem] mb-2 ">Laws</div>
                            <div className='flex items-end gap-4 max-lg:flex-col max-lg:gap-0'>
                                <FormControl name="laws_short_heading" label={"Short Heading"} placeholder="Enter Short Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                                <FormControl name="laws_heading" label={"Heading"} placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                            </div>
                            <br />
                            <div className='grid grid-cols-2 gap-4'>
                            {
                                Array.isArray(datas?.laws) && datas?.laws.map((item, index) => {
                                    const { question, answer } = item
                                    return (
                                        <div className='bg-[#D4DEF1] p-5 rounded-3xl max-lg:p-3' key={index}>
                                            <div className='mb-3'>
                                                <label htmlFor="" className='mb-2 text-[#7D8CA7] text-[.8rem] items-center flex justify-between'>
                                                    <span>Question</span>
                                                    {<div className='closeButton cursor-pointer  bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem]  grid place-items-center rounded-[.7rem] z-10' onClick={() => handleDeleteLaws(index)}>
                                                        <svg className='w-full h-full' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863" stroke="#ED2656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>}
                                                </label>
                                                <input type="text" value={question} className='outline-none w-full h-[3rem] px-5 rounded-xl' placeholder='Question' onChange={(e) => handleChangeLaws(index, e.target.value, "question")} />
                                            </div>
                                            <div className=''>
                                                <label htmlFor="" className='mb-1 text-[#7D8CA7] text-[.8rem] block'><span>Answer</span> </label>
                                                <textarea name="" value={answer} className='outline-none w-full h-[10rem] border border-[#CFD5E2] px-5 py-3 resize-none rounded-2xl' placeholder='Answer' onChange={(e) => handleChangeLaws(index, e.target.value, "answer")}></textarea>

                                            </div>
                                        </div>
                                    )
                                })
                            }
                            </div>
                    

                            <div className='bg-[#d9dcf8] py-3 mt-4 w-fit px-6 rounded-full flex items-center gap-2 cursor-pointer' onClick={handlePlusLaws}>
                                <img src={plus} alt="plus" />
                                <span className='font-MluvkaBold text-secondary capitalize'>Add new</span>
                            </div>
                        </div>
                       

                        <SubmitButton
                                props={{
                                    class: "btn bg-secondary text-white px-12 ml-auto uppercase   py-3 rounded-full w-100 block mt-5 submit hover:bg-primary transition-all duration-300",
                                    text: "Submit",
                                }}
                                buttonLoading={res.isLoading}
                            />

<br />
                    </Form>
                </Formik>
            </div>
        </div>
    )
}

export default EditServices
