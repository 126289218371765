import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import plus from '../dist/webImages/plus.svg'
import useDelete from '../customHooks/useDelete'
import swal from 'sweetalert';
import SkeletonServices from './SkeletonServices';
import useGet from '../customHooks/useGet';
import { MainLanguageContext } from '../context/MainLanguageContext';
import { toast } from 'react-toastify';


const Category = () => {
    const { mainLanguage } = useContext(MainLanguageContext);  
    const [datas, setDatas] = useState()
    const [resget, apiMethodGet] = useGet()

    useEffect(() => {
        if (mainLanguage) {
            apiMethodGet(`servicescategory/service_categories/${mainLanguage}`);
        }
    }, [mainLanguage]);
    useEffect(() => {
        if(!resget.isLoading) {
           setDatas(resget?.data?.data)
        }
       
       }, [resget.data])


    const [resDelete, apiMethodDelete] = useDelete()
    const handleDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to delete?",
            buttons: true,
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    swal("Successfully Delete", "", "success");
                    apiMethodDelete(`services_category/servicecategories/${id}`)
                    const update = datas.filter((item) => item.id !== id)
                    setDatas(update)
                }

            });
    }
    useEffect(() => {
        if(resDelete.data) {
          const {message} = resDelete.data
          toast.success(message);
        }
      }, [resDelete.data])


    if (resget.isLoading) return <SkeletonServices />
  return (
    <div className='services pr-10 max-lg:pr-6'>
    <div className="servicesTop flex justify-between items-center mb-4">
        <h6 className='text-[1rem] mb-2 bookingSectionh relative px-3 font-Mluvka'> Services Category</h6>
        <Link to={"/service/category/create"} className='bg-[#d9dcf8] py-3 px-6 rounded-full flex items-center gap-2 cursor-pointer' >
            <img src={plus} alt="plus" />
            <span className='font-MluvkaBold text-secondary capitalize'>Add Category</span>
        </Link>
    </div>
    <div className="servicesBottom grid grid-cols-4 gap-4 max-lg:grid-cols-1">
        {
           Array.isArray(datas) && datas.map((item) => {
                const { category_title, id } = item
                return (
                    <div className=' border-2 border-[#ddd] py-3 px-6 rounded-2xl relative' key={id}>
                        <div className='closeButton cursor-pointer absolute bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem] top-[-1rem] right-[-1rem] grid place-items-center rounded-[.7rem] z-10' onClick={() => handleDelete(id)}>
                            <svg className='w-full h-full' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863" stroke="#ED2656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <Link to={`/service/category/edit/${id}`} className="servicesCard relative" >
                            <div className="servicesCard__Body">
                                <div className="h4 font-MluvkaBold text-[1.4rem] mt-3">{category_title}</div>
                            </div>
                        </Link>
                    </div>)

                
            })
        } 

    </div>
</div>
  )
}

export default Category