import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import FormControl from '../components/form/FormControl'
import swal from 'sweetalert';
import plus from '../dist/webImages/plus.svg'
import UpdateButton from '../components/UpdateButton';
import SkeletonFaqs from './SkeletonFaqs';
import { MainLanguageContext } from '../context/MainLanguageContext';
import usePost from '../customHooks/usePost';
import useGet from '../customHooks/useGet';
import { toast } from 'react-toastify';

const Faqs = () => {
    const { mainLanguage } = useContext(MainLanguageContext);
    const [loading, setLoading] = useState(true)
    const [resget, apiMethodGet] = useGet()
    useEffect(() => {
        if (mainLanguage) {
            setLoading(true);
            apiMethodGet(`faqlaw/faq/${mainLanguage}`);
        }
    }, [mainLanguage]);


    const [datas, setDatas] = useState({
        sec_two: []
    })
    useEffect(() => {
        if (!resget.isLoading) {
            setLoading(false)
            setDatas(resget?.data?.data)
        }
    }, [resget.data])

    const handleChange = (index, value, type) => {
        setDatas((prevState) => {
            const updatedsec_two = prevState.sec_two.map((faq, i) =>
                i === index ? { ...faq, [type]: value } : faq
            );
            return { ...prevState, sec_two: updatedsec_two };
        });
    };
    const handlePlus = () => {
        const newFaq = {
            id: datas?.sec_two.length + 1,
            question: "",
            answer: "",
        };

        setDatas((prevState) => ({
            ...prevState,
            sec_two: [...prevState.sec_two, newFaq]
        }));
    };
    const handleDelete = (index) => {
        swal({
            title: "Are you sure?",
            text: " you want to remove?",
            buttons: true,
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    setDatas((prevState) => ({
                        ...prevState,
                        sec_two: prevState.sec_two.filter((faq, i) => i !== index)
                    }));
                    swal("Successfully Delete", "", "success");
                }

            });
    }
    const [res, apiMethod] = usePost()
    const requireFeild = [ "heading"];
    const handleSubmit = async (values) => {
        let formdata = new FormData();
        let requireFeildSwal = {
            heading: "Heading",
        };
        let checkerRequried = [];
        for (const item in values) {
            if (requireFeild.includes(item) && values[item] === "") {
                checkerRequried.push(requireFeildSwal[item]);
            }
            formdata.append(item, values[item]);
        }
        for (let index = 0; index < datas?.sec_two.length; index++) {
            formdata.append("faq_question[]", datas?.sec_two[index].question);
            formdata.append("faq_answer[]", datas?.sec_two[index].answers);
        }
        if (datas?.sec_two.length === 0) {
            checkerRequried.push("law")
        }
        if (checkerRequried.length > 0) {
            swal({
                title: "Required Fields are empty! Please fill and try again",
                text: checkerRequried.join(","),
                icon: "error",
                dangerMode: true,
            });
        }

        else {

            apiMethod(`faqlaw/faq/${mainLanguage}`, formdata)
        }
    }
    useEffect(() => {
        if (res.data) {
            const { status, message } = res?.data
            if (status === "false") {
                toast.error(message);
            }
            else {
                toast.success(message);
            }
        }
    }, [res.data])
    if (resget.isLoading && !datas) return <SkeletonFaqs />

    const initialValues = {
        short_heading: resget?.data?.data?.shortHeading,
        heading: resget?.data?.data?.heading,
    }

    return (
        <div className='faqsPage pr-10 max-lg:pr-6'>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                <Form>
                    <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3 max-lg:p-4'>
                        <div className="h4 text-[#7D8CA7] text-[1.1rem] ">Section 1</div>
                        <div className='flex items-end gap-4 max-lg:flex-col max-lg:gap-0'>
                            <FormControl name="heading" label={"Heading"} placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                        </div>

                    </div>
                    <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3 max-lg:p-4'>
                        <div className="faqsPageMain grid grid-cols-2 gap-5 max-lg:grid-cols-1">
                            {
                                Array.isArray(datas?.sec_two) && datas?.sec_two.map((item, index) => {
                                    const { question, answers } = item
                                    return (
                                        <div className='bg-[#D4DEF1] p-5 rounded-3xl max-lg:p-3' key={index}>
                                            <div className='mb-3'>
                                                <label htmlFor="" className='mb-2 text-[#7D8CA7] text-[.8rem] items-center flex justify-between'>
                                                    <span>Question</span>
                                                    {<div className='closeButton cursor-pointer  bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem]  grid place-items-center rounded-[.7rem] z-10' onClick={() => handleDelete(index)}>
                                                        <svg className='w-full h-full' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863" stroke="#ED2656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>}
                                                </label>
                                                <input type="text" value={question} className='outline-none w-full h-[3rem] px-5 rounded-xl' placeholder='Question' onChange={(e) => handleChange(index, e.target.value, "question")} />
                                            </div>
                                            <div className=''>
                                                <label htmlFor="" className='mb-1 text-[#7D8CA7] text-[.8rem] block'><span>Answer</span> </label>
                                                <textarea name="" value={answers} className='outline-none w-full h-[10rem] border border-[#CFD5E2] px-5 py-3 resize-none rounded-2xl' placeholder='Answer' onChange={(e) => handleChange(index, e.target.value, "answers")}></textarea>

                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='bg-[#d9dcf8] py-3 mt-4 w-fit px-6 rounded-full flex items-center gap-2 cursor-pointer' onClick={handlePlus}>
                            <img src={plus} alt="plus" />
                            <span className='font-MluvkaBold text-secondary capitalize'>Add new</span>
                        </div>
                    </div>

                    <UpdateButton loading={res.isLoading} className='btn bg-secondary text-white px-16 uppercase   py-3 rounded-full w-100 block my-5 ml-auto submit hover:bg-primary transition-all duration-300' label={"Update"} />
                </Form>
            </Formik>
        </div>
    )
}

export default Faqs
