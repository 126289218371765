import React, { useContext, useEffect, useState } from 'react'
import { MainLanguageContext } from '../context/MainLanguageContext';
import useGet from '../customHooks/useGet';
import { Pagination, Table } from 'antd';
import usePost from '../customHooks/usePost';
import CaseUpdateModel from './CaseUpdateModel';
import { Link } from 'react-router-dom';
import plus from '../dist/webImages/plus.svg'

const CaseUpdate = () => {
    const { mainLanguage } = useContext(MainLanguageContext);
    const [resget, apiMethodGet] = useGet()
    const [datas, setDatas] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationn, setPaginationn] = useState(10);
    const [searchValue, setSearchValue] = useState("")
    
    const [modelData, setModelData] = useState(null);
    const [modelStatus, setModelStatus] = useState(false);
    const [updateAPi, setUpdateAPi] = useState("")
    useEffect(() => {

            apiMethodGet(`caseManagements/caseUpdatesList/10`);
            setCurrentPage(1)
            
        
    }, [updateAPi]);


    const onChange = (current, pageSize) => {
      setCurrentPage(current)
      let formdata = new FormData();
      formdata.append('search_query', searchValue);
      if ((searchValue).trim()) {
          apiMethod2(`caseManagements/searchCaseUpdatesList/10?page=${current}`, formdata);
      } else {
          apiMethodGet(`caseManagements/caseUpdatesList/10?page=${current}`, formdata);
      }
  };


    useEffect(() => {
        if (!resget.isLoading) {
            const updatedData = resget?.data?.data?.map((item, index) => ({
                ...item,
                key: `item-${index}`, // Add a unique key to each object
            }));
            setDatas(updatedData);
            setPaginationn(resget.data?.pagination)
        }
    }, [resget.data]);
    const columns = [
        {
          title: 'Case Number',
          dataIndex: 'case_number',
          key: 'case_number',
        },
        {
          title: 'Case Title',
          dataIndex: 'case_title',
          key: 'case_title',
        },
        {
          title: 'Client Name',
          dataIndex: 'client_name',
          key: 'client_name',
        },
        {
          title: 'Description',
          dataIndex: 'update_description',
          key: 'update_description',
        },
        {
          title: 'Date',
          dataIndex: 'update_dateTime',
          key: 'update_dateTime',
        },
        // {
        //  title:'Reply',
        //  key: 'x',
        //  render: (datass) => 
        //   <div>
        //     {
        //       datass?.updated_by === "Client" &&
        //       <div onClick={() => handleMOdel(datass)} className='cursor-pointer px-2 py-2 pb-3 leading-[1] bg-primary text-center rounded-lg font-MluvkaBold text-white'>Reply</div>
        //     }
        //   </div>, 
        // }

      ];

      const handleMOdel = (item) => {
        setModelData(item)
        setModelStatus(true)
      }


      const [res2, apiMethod2] = usePost()
      let debounceTimer;
      const debounce = (func, delay) => {
          return (...args) => {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => {
              func(...args);
            }, delay);
          };
        };
      
      
        const executeApiCall = (e) => {
          setSearchValue(e.target.value)
          let formdata = new FormData();
          formdata.append('search_query', e.target.value);
          if ((e.target.value).trim()) {
              apiMethod2(`caseManagements/searchCaseUpdatesList/10?page=${currentPage}`, formdata);
          } else {
              apiMethodGet(`caseManagements/caseUpdatesList/10?page=${currentPage}`, formdata);
          }
        };
      
        const handleChange = debounce(executeApiCall, 1000)
  
        useEffect(() => {
          setDatas([])
          if (res2.data) {
              setDatas(res2?.data?.data);
              setPaginationn(res2?.data?.pagination)
          }
      }, [res2.data]);  

if(!datas) return '';
  return (
    <div className='DepartmentsPage pr-10 max-lg:pr-6'>
    <div className="DepartmentsPageTop flex justify-between items-center mb-4">
        { <h6 className='text-[1rem] mb-2 bookingSectionh relative px-3 font-Mluvka'> Case Update</h6>}
        <div className='flex gap-1'>
            <div className="inputBox w-[16rem] max-lg:hidden">
                <input type="text" onChange={handleChange} className='w-full border h-[2.8rem] rounded-full px-4 border-[#ddd] outline-none' placeholder='Search' />
            </div>
            <Link to={"/case/update/create"} className='bg-[#d9dcf8] py-3 px-6 rounded-full flex items-center gap-2 cursor-pointer'>
                            <img src={plus} alt="plus" />
                            <span className='font-MluvkaBold text-secondary capitalize'>Add new</span>
                        </Link>
        </div>
    </div>
    <div className='tableMain'>
    <Table dataSource={datas} columns={columns} />

    {modelStatus && <CaseUpdateModel updateAp={setUpdateAPi}  data={modelData} modelStatus={modelStatus} modelStatusUpdate={setModelStatus} />}
    </div>

    <div className='my-4'>
                <Pagination
                    onChange={onChange}
                    current={currentPage}
                    total={paginationn?.total}
                    pageSize={10}
                />
            </div>
    </div>
  )
}

export default CaseUpdate