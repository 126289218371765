import React, { useState } from 'react'
import TermsAndConditions from './TermsAndConditions';
import PrivicyPolicy from './PrivicyPolicy';
import { OtherPageTab } from '../data/data';
import Diversity from './Diversity';
import Expertise from './Expertise';
import LegalLibrary from './LegalLibrary';

const OtherPage = () => {
    const TabList = {
        "Term & Conditions": <TermsAndConditions />,
        "Privicy Policy": <PrivicyPolicy />,
        "Diversity": <Diversity />,
        "Expertise": <Expertise />,
        "Legal Library": <LegalLibrary />,
    };
    const [tabIndex, setTabIndex] = useState("Term & Conditions");
    const handleWebtabUpdate = (label) => {
        setTabIndex(label)
    }
  return (
    <div className='OtherPage pr-10 max-lg:pr-6'>
         <ul className='list flex gap-6 mb-4'>
                        {
                            OtherPageTab.map((item, index) => {
                                const { label } = item
                                return (
                                    <li key={index} className={`font-MluvkaBold py-2 px-6 cursor-pointer bg-[#EFF4FD] rounded-full transition-all duration-300 ease-in-out border-2   ${tabIndex === label ? " active border-secondary" : "border-transparent"}`}  onClick={() => handleWebtabUpdate(label)}>{label}</li>
                                )
                            })
                        }
                    </ul>

                    <div className="OtherPageBody">
                    {TabList[tabIndex]}
            </div>
    </div>
  )
}

export default OtherPage