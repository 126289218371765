import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import LegalSecretaryCard from './LegalSecretaryCard';

const DraggableLegalSecretaryCard = ({ item, index, id, order_number, moveCard, data, delet }) => {
    const ref = React.useRef(null);

    const [{ isDragging }, drag] = useDrag({
        type: "pet",
        item: { index, id, order_number },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: "pet",
        drop: (draggedItem) => {
            if (draggedItem.index !== index) {
                // moveCard(draggedItem.index, index, draggedItem, item);
                draggedItem.index = index;
            }
        },
    });

    drag(drop(ref));

    return (
        <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <LegalSecretaryCard 
                data={item} 
                page={"team"} 
                alldata={data} 
                deleted={() => delet(item)} 
            />
        </div>
    );
};

export default DraggableLegalSecretaryCard;
