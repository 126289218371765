// return the token from the session storage
export const getTokenSession = () => {
    return localStorage.getItem("token") || null;
  };
export const getLanguage = () => {
    return localStorage.getItem("lang") || 'en';
  };
  // remove the token and user from the session storage
  export const removeTokenSession = () => {
    localStorage.clear();
  };
  
  // set the token and user from the session storage
  export const setTokenSession = (token) => {
    localStorage.setItem("token", token);
  };
  export const setLanguage = (lang) => {
    localStorage.setItem("lang", lang);
  };
  