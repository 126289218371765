import React, { useContext } from 'react'
import img1 from "../dist/webImages/dropdown.svg"
import HeaderProfile from './HeaderProfile'
import SkeletonHeader from './SkeletonHeader'
import { FaBars } from 'react-icons/fa'
import { MainMenuActiveContext } from '../context/MainMenuActiveContext'
import { MainLanguageContext } from '../context/MainLanguageContext'
import { MainBookingContext } from '../context/MainBooking';

const Header = ({data,loading}) => {
  const { handlelanguage,mainLanguage } = useContext(MainLanguageContext);
  const { bookingContext } = useContext(MainBookingContext);
    const { handleGetEditValue } = useContext(MainMenuActiveContext);
  
    // useEffect(() => {
    //   // Initialize Pusher
    //   const pusher = new Pusher('96a1a9b7ae897a91bf91', {
    //     cluster: 'ap2',
    //     encrypted: true
    //   });
  
    //   // Subscribe to the channel
    //   const channel = pusher.subscribe('booking-notification-channel');
  
    //   // Bind a callback for the `pusher:subscription_succeeded` event
    //   channel.bind('pusher:subscription_succeeded', () => {
    //     console.log('Successfully subscribed to my-channel');
    //   });
  
    //   // Bind a callback for the `chat` event
    //   channel.bind('notify-event', (data) => {
    //     console.log('Received message:', data.bookingDetail);
    //     toast.success(data.bookingDetail?.client_email);
    //   });
  
    //   // Clean up the subscription when component unmounts
    //   return () => {
    //     channel.unbind_all();
    //     channel.unsubscribe();
    //   };
    // }, []);



    if(loading) return <SkeletonHeader />
    const {name,profile_image} = data
  return (
    <div className='header py-6 pr-10 flex justify-between items-center max-lg:pr-6'>
      <div className="header__left max-lg:flex max-lg:items-center">
        <div className='hidden max-lg:block' onClick={() => handleGetEditValue(true)}>
        <FaBars className='text-[1.4rem] mr-3 '/>
        </div>
        <div className='font-Mluvka text-[1.938rem] max-lg:text-[1.1rem]'><span className='font-MluvkaBold text-secondary'>{bookingContext}</span> Bookings</div>
      </div>
      <div className="header__right flex items-center gap-4">
      
        <div className="inputBox   relative languages w-[10rem] max-lg:w-[7rem]">
        <img src={img1} alt="dropdown.svg" />
            <select value={mainLanguage} onChange={(e) => handlelanguage(e.target.value)} className='w-full bg-transparent border border-[#ddd]  h-[2.8rem] px-4 rounded-3xl  outline-0 capitalize appearance-none max-lg:text-[.9rem]' name="" id="">
                <option value="en">English</option>
                <option value="ar">Arabic</option>
                {/* <option value="ru">Russian</option>
                <option value="ch">Chinese</option> */}
            </select>
        </div>
        <div className='profile flex items-center gap-4'>
            <div className="profile__left max-lg:hidden">
                <h2 className='leading-[1] text-[1.4rem] font-MluvkaBold'>{name}</h2>
                <p className='m-0 text-right text-secondary text-[.8rem] font-Mluvka'>Supper Admin</p>
            </div>
          <HeaderProfile profile_image={profile_image} />
        </div>
      </div>
      {/* close header__right  */}
    </div>
  )
}

export default Header
