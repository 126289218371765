import React, { useEffect } from 'react'
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { StringConvert } from '../components/StringConvert';
import useDelete from '../customHooks/useDelete';
import { toast } from 'react-toastify';

const truncateText = (text, maxLength) => {
    if(text) {
      if (text.length <= maxLength) {
        return text;
      }
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };


const EventsCard = ({data,alldata,deleted}) => {
    const {id,slug,title,description,author_name,event_images,date} = data
    const [resDelete, apiMethodDelete] = useDelete()
    const handleDelete = () => {
        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to delete?",
            buttons: true,
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    swal("Successfully Delete", "", "success");
                    apiMethodDelete(`events/delete/${id}`)
                }

            });
    }
    useEffect(() => {
        if(resDelete.data) {
          const {message} = resDelete.data
          const update = alldata.filter((item) => item.id !== id)
          deleted(update)
          toast.success(message);
        }
      }, [resDelete.data])
    const formattedDate = dayjs(date).format('MMMM D, YYYY');

    const maxLength = 200;
    const truncatedText = truncateText(description, maxLength);



    return (
        <div className='NewsCard relative shadow-custom border border-[#D4DEF1] rounded-3xl bg-white'>
            <div className='closeButton cursor-pointer absolute bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem] top-[.7rem] right-[.7rem] grid place-items-center rounded-[.7rem] z-10' onClick={handleDelete}>
                <svg className='w-full h-full' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863" stroke="#ED2656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
            <Link className="NewsCardMain p-4 grid grid-cols-[4fr,6fr] gap-4 max-lg:grid-cols-1" to={`/events/edit/${slug}`}>
                <div className="NewsCard__left max-lg:mb-4">
                    <img src={event_images[0]} className='w-full rounded-2xl h-[10rem] object-cover' alt="1.png" />
                </div>
                <div className="NewsCard__leftRight pr-8">
                    <div className="h2 text-[1.25rem] capitalize font-MluvkaLight leading-[1.2] mb-2">{title}</div>
                    <div className='text-[#393946] text-[.8rem] leading-[1.5] mb-4'>{StringConvert(truncatedText)}</div>
                    <ul className='list'>
                        <li className='text-primary flex justify-between items-center'><span className='font-MluvkaLight'>{author_name}</span> <span className='font-MluvkaBold'>{formattedDate}</span></li>
                    </ul>
                </div>
            </Link>
        </div>
    )
}

export default EventsCard
