export const menu = [
  {
    id:1,
    label: "Dashboard",
    link: "/",
  },
  {
    id:2,
    label: "Booking",
    link: "/booking",
  },
  {
     id:3,
    label: "Case Management",
    link: "/case",
    dropdown: [
      {
        label: "Case List",
        link: "/case",
      },
      {
        label: "Case Update",
        link: "/case/update",
      },
      {
        label: "Case Inquires",
        link: "/case/inquires",
      },
    ]
  },
  {
     id:6,
    label: "Team",
    link: "/team",
  },
  {
     id:7,
    label: "Departments",
    link: "/departments",
  },
  {
     id:8,
    label: "Services",
    link: "/services",
  },
  {
     id:9,
    label: "Services Category",
    link: "/service/category",
  },
  {
     id:10,
    label: "News and updates",
    link: "/news",
  },
  {
     id:11,
    label: "Events",
    link: "/events",
  },
  {
     id:12,
    label: "Gallery",
    link: "/gallery",
  },
  {
     id:13,
    label: "Reviews",
    link: "/reviews",
  },
  {
     id:14,
    label: "Mobile FAQs",
    link: "/faqs",
  },
  {
     id:15,
    label: "Elements",
    link: "/elements",
  },
  {
     id:16,
    label: "Quote",
    link: "/quote",
  },
  {
     id:17,
    label: "WebContent",
    link: "/webcontent",
  },
  {
     id:18,
    label: "APP Content",
    link: "/appcontent",
  },
];

export const BookingTab = [
  {
    label: "Bookings",
    active: "",
    color: "#3D50DF",
  },
  {
    label: "Pending Bookings",
    active: "booked",
    color: "#B7903B",
  },
  {
    label: "Completed",
    active: "completed",
    color: "#00AE4F",
  },
  {
    label: "Cancelled",
    active: "cancelled",
    color: "#ED2656",
  },
];
export const webContentTab = [
  {
    label: "Home",
  },
  {
    label: "About Us",
  },
  {
    label: "News",
  },
  {
    label: "Event",
  },
  {
    label: "Team",
  },
  {
    label: "Services",
  },
  // {
  //   label: "Faqs",
  // },
  // {
  //   label: "Laws",
  // },
  {
    label: "Contact Us",
  },
  {
    label: "Other Page",
  },
];
export const appContentTab = [
  {
    label: "On Boarding",
  },
  {
    label: "Terms and conditions",
  },
  {
    label: "Privacy",
  },
];
export const OtherPageTab = [
  {
    label: "Term & Conditions",
  },
  {
    label: "Privicy Policy",
  },
  {
    label: "Diversity",
  },
  {
    label: "Expertise",
  },
  {
    label: "Legal Library",
  },

];
