import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonAboutUs = () => {
  return (
    <SkeletonTheme baseColor="#fff" highlightColor="#f0f0f0">
    <div className='aboutPage pr-10 max-lg:pr-6'>
      <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
        <Skeleton height={30} width={150} className="mb-4" />
        <Skeleton height={50} className="w-full mb-4 rounded-xl" />
        <div className='grid grid-cols-2 gap-3 max-lg:grid-cols-1 max-lg:gap-1'>
          <Skeleton height={100} className="w-full mb-4 rounded-xl" />
          <Skeleton height={100} className="w-full mb-4 rounded-xl" />
        </div>
      </div>

      <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
        <Skeleton height={30} width={150} className="mb-4" />
        <div className="Section2Main grid grid-cols-3 gap-3 max-lg:grid-cols-1">
          <div className="Section2Main__left">
            <Skeleton height={50} className="w-full mb-4 rounded-xl" />
            <Skeleton height={150} className="w-full mb-4 rounded-xl" />
            <Skeleton height={50} className="w-full mb-4 rounded-xl" />
          </div>
          <div className="section2Main__right">
            <Skeleton height={28} width={150} className="mb-1" />
            <div className='h-[28rem] relative'>
              <Skeleton height="100%" className="w-full rounded-3xl object-cover" />
             
            </div>
          </div>
          <div className="Section2Main__left">
            <Skeleton height={150} className="w-full mb-4 rounded-xl" />
          </div>
        </div>
      </div>

      <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
        <div className='flex justify-between'>
          <Skeleton height={30} width={150} className="mb-4" />
          <div className='bg-[#d9dcf8] py-3 px-6 rounded-full flex items-center gap-2 cursor-pointer'>
            <Skeleton height={24} width={24} circle={true} />
            <Skeleton height={20} width={80} />
          </div>
        </div>
        <Skeleton height={50} className="w-full mb-4 rounded-xl" />
        <div className="section3Main grid grid-cols-3 gap-3 mt-4 max-lg:grid-cols-1">
          {Array(3).fill().map((_, index) => (
            <div className="section3MainBox relative bg-[#DEE5F2] p-4 rounded-lg" key={index}>
              <Skeleton height={50} className="w-full mb-4 rounded-xl" />
              <Skeleton height={50} className="w-full mb-4 rounded-xl" />
              <Skeleton height={100} className="w-full mb-4 rounded-xl" />
            </div>
          ))}
        </div>
      </div>

      <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
        <div className='flex justify-between'>
          <Skeleton height={30} width={150} className="mb-4" />
          <div className='bg-[#d9dcf8] py-3 px-6 rounded-full flex items-center gap-2 cursor-pointer'>
            <Skeleton height={24} width={24} circle={true} />
            <Skeleton height={20} width={80} />
          </div>
        </div>
        <div className="section4Main grid grid-cols-3 gap-3 mt-4 max-lg:grid-cols-1">
          {Array(3).fill().map((_, index) => (
            <div className="section4MainBox bg-[#DEE5F2] p-4 rounded-lg relative" key={index}>
              <Skeleton height={50} className="w-full mb-4 rounded-xl" />
              <Skeleton height={100} className="w-full mb-4 rounded-xl" />
              <Skeleton height={28} width={150} className="mb-1" />
              <div className='h-[10rem] relative'>
                <Skeleton height="100%" className="w-full rounded-3xl object-cover" />
                
              </div>
              <Skeleton height={50} className="w-full mb-4 rounded-xl" />
              <Skeleton height={50} className="w-full mb-4 rounded-xl" />
            </div>
          ))}
        </div>
      </div>

      <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
        <div className=''>
          <Skeleton height={30} width={150} className="mb-4" />
          <div className="section5Main grid grid-cols-[3fr,7fr] gap-3 max-lg:grid-cols-1">
            <div className="section5Main__Left">
              <Skeleton height={50} className="w-full mb-4 rounded-xl" />
              <Skeleton height={50} className="w-full mb-4 rounded-xl" />
              <Skeleton height={100} className="w-full mb-4 rounded-xl" />
            </div>
            <div className="section5Main__RIght">
              <Skeleton height={20} width={100} className="mb-4" />
              <div className="sec5Main grid grid-cols-5 gap-3 max-lg:grid-cols-3">
                {Array(5).fill().map((_, index) => (
                  <div className="sec5MainBox relative" key={index}>
                    <div className='h-[10rem] relative'>
                      <Skeleton height="100%" className="w-full rounded-3xl object-cover" />
                     
                    </div>
                  </div>
                ))}
              </div>
              <div className='bg-[#d9dcf8] w-fit mt-3 py-3 px-6 rounded-full flex items-center gap-2 cursor-pointer'>
                <Skeleton height={24} width={24} circle={true} />
                <Skeleton height={20} width={80} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
        <div className="h4 text-[#7D8CA7] text-[1.1rem] ">Paragraph</div>
        <div className="section6Main grid grid-cols-[3fr,7fr] gap-3 max-lg:grid-cols-1 max-lg:gap-0">
          <div className="section6Main__left">
            <Skeleton height={50} className="w-full mb-4 rounded-xl" />
          </div>
          <div className="section6Main__Right">
            <Skeleton height={50} className="w-full mb-4 rounded-xl" />
          </div>
        </div>
      </div>
    </div>
    </SkeletonTheme>
  );
};

export default SkeletonAboutUs;
