import React, { useContext, useEffect, useState } from 'react'
import CaseCard from './CaseCard'
import { Link } from 'react-router-dom'
import plus from '../dist/webImages/plus.svg'
import { MainLanguageContext } from '../context/MainLanguageContext'
import { Pagination } from 'antd'
import useGet from '../customHooks/useGet'
import SkeletonBookingsCard from '../Bookings/SkeletonBookingsCard'
import usePost from '../customHooks/usePost'

const CaseManagement = () => {
    const { mainLanguage } = useContext(MainLanguageContext); 
    const [datas, setDatas] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [paginationn, setPaginationn] = useState(12);
    const [searchValue, setSearchValue] = useState("")
    const [resget, apiMethodGet] = useGet()

    useEffect   (() => {
        if (mainLanguage) {
            apiMethodGet(`caseManagements/8?page=1`);
        }
    }, [mainLanguage]);

    const [res2, apiMethod2] = usePost()
    const onChange = (current, pageSize) => {
        setCurrentPage(current)
        let formdata = new FormData();
        formdata.append('search_query', searchValue);
        if ((searchValue).trim()) {
            apiMethod2(`caseManagements/searchCaseList/8?page=${current}`, formdata);
        } else {
            apiMethodGet(`caseManagements/8?page=${current}`);
        }
    };


    let debounceTimer;
    const debounce = (func, delay) => {
        return (...args) => {
          clearTimeout(debounceTimer);
          debounceTimer = setTimeout(() => {
            func(...args);
          }, delay);
        };
      };
    
    
      const executeApiCall = (e) => {
        setSearchValue(e.target.value)
        let formdata = new FormData();
        formdata.append('search_query', e.target.value);
        if ((e.target.value).trim()) {
            apiMethod2(`caseManagements/searchCaseList/8?page=${currentPage}`, formdata);
        } else {
            apiMethodGet(`caseManagements/8?page=${currentPage}`);
        }
      };
      const handleChange = debounce(executeApiCall, 1000)

      useEffect(() => {
        if (!resget.isLoading) {
            setDatas(resget?.data?.data)
            setPaginationn(resget.data?.pagination)
        }

    }, [resget.data])

    useEffect(() => {
        setDatas([])
        if (res2.data) {
            setDatas(res2?.data?.data);
            setPaginationn(res2?.data?.pagination)
        }
    }, [res2.data]);


  return (
    <div className='CaseManagement pr-10'>
  <div className="TeamPageTop flex justify-between items-center">
                <h6 className='text-[1rem] mb-2 bookingSectionh relative px-3 font-Mluvka capitalize'>Case Management</h6>
                <div className='flex gap-1'>
                    <div className="inputBox w-[16rem] max-lg:hidden">
                        <input type="text" onChange={handleChange} className='w-full border h-[2.8rem] rounded-full px-4 border-[#ddd] outline-none' placeholder='Search' />
                    </div>
                <Link to={"/case/create"} className='bg-[#d9dcf8] py-3 px-6 rounded-full flex items-center gap-2 cursor-pointer'>
                    <img src={plus} alt="plus" />
                    <span className='font-MluvkaBold text-secondary capitalize'>Add new</span>
                </Link>
                </div>
            </div>
    <div className="CaseManagementMain mt-4">
        <div className="grid grid-cols-4 gap-4">
        {resget.isLoading ?
                    Array.from({ length: 6 }).map((_, index) => (
                        <React.Fragment key={index}>
                            <SkeletonBookingsCard />
                        </React.Fragment>
                    ))
                    :
                    Array.isArray(datas) && datas.map((item,index) => {
                return (
                    <React.Fragment key={index}>
                    <CaseCard data={item} alldata={datas} deleted={setDatas} />
                    </React.Fragment>
                )
            })}
        </div>
    </div>  

    <div className='mt-4'>
                <Pagination
                    onChange={onChange}
                    defaultCurrent={currentPage}
                    total={paginationn?.total}
                    pageSize={8}
                />
            </div>

    </div>
  )
}

export default CaseManagement