import React, { useContext, useEffect, useState } from "react";
import profile from "../dist/webImages/profile2.png";
import camera from "../dist/webImages/camera.svg";
import swal from "sweetalert";
import FormControl from "../components/form/FormControl";
import { Field, Form, Formik } from "formik";
import SubmitButton from "../components/SubmitButton";
import CKEditors from "../components/form/CKEditors";
import { Link, useNavigate } from 'react-router-dom';
import back from "../dist/webImages/back.svg";
import SkeletonCreateEdit from "./SkeletonCreateEdit";
import usePost from "../customHooks/usePost";
import { MainLanguageContext } from "../context/MainLanguageContext";
import { toast } from "react-toastify";

const CreateTeam = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)
  const { mainLanguage } = useContext(MainLanguageContext);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const [datas, setDatas] = useState({
    discription: "",
  });
  const { discription } = datas;



  let initialValues = {
    meta_tag: "",
    meta_description: "",
    schema_code: "",
    name: "",
    email: "",
    designation: "",
  };
  const [imgValue, setiImgValue] = useState("");
  const [imgPath, setImgPath] = useState();
  const [imgValue2, setiImgValue2] = useState("");
  const [imgPath2, setImgPath2] = useState();
  const [expertise, setExpertise] = useState([""]);
  const [education, setEducation] = useState([""]);
  const [skills, setSkills] = useState([""]);
  const [memberships, setMemberships] = useState([""]);
  const [practicesarea, setPracticesarea] = useState([""]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file?.type?.includes("image")) {
        let path = (window.URL || window.webkitURL).createObjectURL(file);
        setImgPath(path);
        setiImgValue(file);
      } else {
        swal("Only use Image", "", "warning");
      }
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    if (
      e.dataTransfer.files[0] &&
      e.dataTransfer.files[0]?.type?.includes("image")
    ) {
      let path = (window.URL || window.webkitURL).createObjectURL(
        e.dataTransfer.files[0]
      );
      setImgPath(path);
      const file = e.dataTransfer.files[0];
      setiImgValue(file);
    } else {
      swal("Only use Image", "", "warning");
    }
  };
  const handleFileUpload2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file?.type?.includes("image")) {
        let path = (window.URL || window.webkitURL).createObjectURL(file);
        setImgPath2(path);
        setiImgValue2(file);
      } else {
        swal("Only use Image", "", "warning");
      }
    }
  };
  const handleDrop2 = (e) => {
    e.preventDefault();
    if (
      e.dataTransfer.files[0] &&
      e.dataTransfer.files[0]?.type?.includes("image")
    ) {
      let path = (window.URL || window.webkitURL).createObjectURL(
        e.dataTransfer.files[0]
      );
      setImgPath2(path);
      const file = e.dataTransfer.files[0];
      setiImgValue2(file);
    } else {
      swal("Only use Image", "", "warning");
    }
  };

  const handleAddExpertise = () => {
    if (expertise.length > 10) {
      swal(
        "Maximum Limit Reached",
        "You can only add up to 10 areas of expertise.",
        "warning"
      );
      return;
    } else {
      setExpertise((prevExpertise) => [...prevExpertise, ""]);
    }
  };

  const handleRemoveExpertise = (index) => {
    setExpertise((prevExpertise) =>
      prevExpertise.filter((_, i) => i !== index)
    );
  };

  const handleExpertiseChange = (index, value) => {
    setExpertise((prevExpertise) =>
      prevExpertise.map((item, i) => (i === index ? value : item))
    );
  };



  const handleAddEducation = () => {
    setEducation((prevEducation) => [...prevEducation, ""]);
};
  const handleRemoveEducation = (index) => {
    setEducation((prevEducation) =>
      prevEducation.filter((_, i) => i !== index)
    );
  };
  const handleEducationChange = (index, value) => {
    setEducation((prevEducation) =>
      prevEducation.map((item, i) => (i === index ? value : item))
    );
  };
  const handleAddSkills = () => {
    setSkills((prevSkills) => [...prevSkills, ""]);
};
  const handleRemoveSkills = (index) => {
    setSkills((prevSkills) =>
      prevSkills.filter((_, i) => i !== index)
    );
  };
  const handleSkillsChange = (index, value) => {
    setSkills((prevSkills) =>
      prevSkills.map((item, i) => (i === index ? value : item))
    );
  };
  const handleAddMemberships = () => {
    setMemberships((prevMemberships) => [...prevMemberships, ""]);
};
  const handleRemoveMemberships = (index) => {
    setMemberships((prevMemberships) =>
      prevMemberships.filter((_, i) => i !== index)
    );
  };
  const handleMembershipsChange = (index, value) => {
    setMemberships((prevMemberships) =>
      prevMemberships.map((item, i) => (i === index ? value : item))
    );
  };
  const handleAddPracticesarea = () => {
    setPracticesarea((prevPracticesarea) => [...prevPracticesarea, ""]);
};
  const handleRemovePracticesarea = (index) => {
    setPracticesarea((prevPracticesarea) =>
      prevPracticesarea.filter((_, i) => i !== index)
    );
  };
  const handlePracticesareaChange = (index, value) => {
    setPracticesarea((prevPracticesarea) =>
      prevPracticesarea.map((item, i) => (i === index ? value : item))
    );
  };

  const handlediscriptionChange = (e) => {
    setDatas((d) => ({ ...d, discription: e }));
  };
  const [res, apiMethod] = usePost()
  const requireFeild = ["meta_tag","meta_description","name", "lawyer_email","designation"];
  const handleSubmit = async (values) => {
    let formdata = new FormData();
    let requireFeildSwal = {
      meta_tag: "Meta Tag",
      meta_description: "Meta Description",
      name: "Name",
      lawyer_email: "Email",
      'designation': "designation",
    };
    let checkerRequried = [];
    for (const item in values) {
      if (requireFeild.includes(item) && values[item] === "") {
        checkerRequried.push(requireFeildSwal[item]);
      }
    }
    formdata.append("team_translation[meta_tag]", values["meta_tag"]);
    formdata.append("team_translation[meta_description]", values["meta_description"]);
    formdata.append("team_translation[schema_code]", values["schema_code"]);
    formdata.append("team_translation[name]", values["name"]);
    formdata.append("team_translation[location]", values["location"]);
    formdata.append("team_translation[designation]", values["designation"]);
    formdata.append("lawyer_email", values["lawyer_email"]);
    formdata.append("bod", values["bod"]);
    if (!imgValue) {
      checkerRequried.push("Profile Image");
    }
    const expertiseupdate = expertise.filter(item => item.trim());
    if (!expertiseupdate.length) {
      checkerRequried.push("Expertise");
    }

    if (checkerRequried.length > 0) {
      swal({
        title: "Required Fields are empty! Please fill and try again",
        text: checkerRequried.join(","),
        icon: "error",
        dangerMode: true,
      });
    }
    else {
      formdata.append("lowyer_image", imgValue);
      formdata.append("qr_code_image", imgValue2);
      formdata.append("team_translation[detail]", datas.discription);
      if(expertise[0]) {
        for (let index = 0; index < expertise.length; index++) {
          formdata.append("team_translation[expertise][]", expertise[index] ?? "");
        }
      }
      if(education[0]) {
      for (let index = 0; index < education.length; index++) {
        formdata.append("team_translation[educations][]", education[index] ?? "");
      }
      }
      if(skills[0]) {
      for (let index = 0; index < skills.length; index++) {
        formdata.append("team_translation[skills][]", skills[index] ?? "");
      }
      }
      if(memberships[0]) {
      for (let index = 0; index < memberships.length; index++) {
        formdata.append("team_translation[memberships][]", memberships[index] ?? "");
      }
      }
      if(practicesarea[0]) {
      for (let index = 0; index < practicesarea.length; index++) {
        formdata.append("team_translation[practice_areas][]", practicesarea[index] ?? "");
      }
    }
      apiMethod(`teams/${mainLanguage}`, formdata)
    }
  };
  useEffect(() => {
    if (res.data) {
      const { status, message } = res?.data
      if (status === "false") {
        toast.error(message);
      }
      else {
        navigate("/team")
        toast.success(message);
      }
    }
  }, [res.data])



  if (loading) return <SkeletonCreateEdit heading={"Create Team"} />
  return (
    <div className='createTeam pr-10 max-lg:pr-6'>
      <Link to={"/team"} className="back flex items-center mb-5 gap-2">
        <img src={back} className='w-[2rem]' alt="" />
        <span className='text-[1.4rem] font-MluvkaBold'>Create Team</span>
      </Link>

      <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3 max-lg:p-2'>
        <div className={`TeamModel  transition-all duration-300  bg-white rounded-xl`} >
          <div className=" overflow-auto modelBox">
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              <Form name="myForm">
                <div className='bg-[#EFF4FD] py-4'>
                  <FormControl name="meta_tag" label={"Meta Title"} placeholder="Enter Meta Title" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                  <div className='grid grid-cols-2 gap-3 max-lg:grid-cols-1 max-lg:gap-0'>
                    <FormControl name="meta_description" label={"Meta Description"} placeholder="Enter Meta Description" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                    <FormControl name="schema_code" label={"Scheme Code"} placeholder="Scheme Code" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                  </div>
                </div>
                <div className="TeamBox p-5 rounded-xl">
                  <div
                    className="w-[5rem] h-[5rem] relative mt-4"
                    onDrop={handleDrop}
                  >
                    <img
                      src={imgPath || profile}
                      className="w-full h-full rounded-3xl object-cover border-2 border-[#C0CCE2]"
                      alt=""
                    />
                    <div className="TeamBoxinput w-[2rem] h-[2rem] bg-[#C0CCE2] grid place-items-center rounded-full absolute right-[-1rem] top-[-1rem] cursor-pointer z-1">
                      <input
                        type="file"
                        onChange={handleFileUpload}
                        className="absolute inset-0 opacity-0 cursor-pointer"
                      />
                      <img src={camera} alt="camera" className="cursor-pointer" />
                    </div>
                  </div>

                  <div className="form mt-7">
                    <FormControl
                      name="name"
                      placeholder="Enter your Name"
                      className="outline-none w-full h-[2.7rem] border border-[#CFD5E2] px-5 rounded-full"
                      control="input"
                    />
                    <FormControl
                      name="lawyer_email"
                      placeholder="Enter your Email"
                      className="outline-none w-full h-[2.7rem] border border-[#CFD5E2] px-5 rounded-full"
                      control="input"
                    />
                    <FormControl
                      name="designation"
                      placeholder="Enter your Designation"
                      className="outline-none w-full h-[2.7rem] border border-[#CFD5E2] px-5 rounded-full"
                      control="input"
                    />
                    <FormControl 
                                     name="location"
                                      label={"Location"} 
                                      placeholder="Enter Meta Location" 
                                      className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none border border-[#CFD5E2]" 
                                      control="textarea" />


<label class="mb-1 block" for="location">Discription</label>
                    <CKEditors
                      label={"discription"}
                      data={discription}
                      update={handlediscriptionChange}
                    />
<div className="bg-[#EFF4FD] p-4">
<label className="mb-1  mt-3 block text-[#7D8CA7] text-[.8rem]">Qr Code </label>
                <div className=' w-[20rem] h-[10rem] relative' onDrop={handleDrop2}   >
                  {imgPath2 ?
                    <img src={imgPath2|| profile} className='w-full h-full rounded-3xl object-cover ' alt="" />
                    :
                    <div className='w-full h-full  rounded-3xl object-cover  bg-white'></div>
                  }
                  <div className="TeamBoxinput w-[3rem] h-[3rem] bg-[#C0CCE2] grid  place-items-center rounded-full absolute mx-auto right-0 left-0 top-[50%] transform translate-y-[-50%] cursor-pointer z-1">
                    <input type="file" onChange={handleFileUpload2} className='absolute inset-0 opacity-0 cursor-pointer' />
                    <img src={camera} alt="camera" className='cursor-pointer w-full p-3' />
                  </div>
                </div>
</div>

                    <div className="expertiseMain grid grid-cols-2 gap-1 mt-3 pr-1 max-h-[10rem] overflow-y-auto max-lg:grid-cols-1">
                      {expertise.map((item, index) => {
                        return (
                          <div className="expertisebox relative" key={index}>
                            {index !== 0 && (
                              <div
                                className="close absolute right-[1rem] top-[50%] translate-y-[-50%] cursor-pointer"
                                onClick={() => handleRemoveExpertise(index)}
                              >
                                <svg
                                  width="17"
                                  height="17"
                                  viewBox="0 0 17 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863"
                                    stroke="#ff0000"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            )}
                            <input
                              placeholder="Expertise"
                              value={item}
                              onChange={(e) =>
                                handleExpertiseChange(index, e.target.value)
                              }
                              className="outline-none w-full h-[2.7rem] border border-[#CFD5E2] my-1 px-5 py-3 rounded-full"
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div className="mt-4">
                      <div
                        className="h6 cursor-pointer font-Mluvka text-secondary"
                        onClick={handleAddExpertise}
                      >
                        + Add More Expertise
                      </div>
                    </div>

                      <div className="teaam mt-4 grid grid-cols-2 gap-4">
                        <div className="teaamBox">
                        <div className="h2 text-primary text-[1.2rem]">EDUCATION</div>
                        <ul className="list">
                          {
                            Array.isArray(education) && education.map((item,index) => {
                              return (
                                <li className="relative" key={index}>
                      {index !== 0 && (
                              <div
                                className="close absolute right-[1rem] top-[50%] translate-y-[-50%] cursor-pointer"
                                onClick={() => handleRemoveEducation(index)}
                              >
                                <svg
                                  width="17"
                                  height="17"
                                  viewBox="0 0 17 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863"
                                    stroke="#ff0000"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            )}
                            <input
                              placeholder="Education list"
                              value={item}
                              onChange={(e) =>
                                handleEducationChange(index, e.target.value)
                              }
                              className="outline-none w-full h-[2.7rem] border border-[#CFD5E2] my-1 px-5 py-3 rounded-full"
                            />
                                </li>
                              )
                            })
                          }
                        
                        </ul>
                        <div
                        className="h6 cursor-pointer font-Mluvka text-secondary"
                        onClick={handleAddEducation}
                        >
                        + Add More Education
                      </div>
                        </div>
                        <div className="teaamBox">
                        <div className="h2 text-primary text-[1.2rem]">SKILLS</div>
                        <ul className="list">
                          {
                            Array.isArray(skills) && skills.map((item,index) => {
                              return (
                                <li className="relative" key={index}>
                      {index !== 0 && (
                              <div
                                className="close absolute right-[1rem] top-[50%] translate-y-[-50%] cursor-pointer"
                                onClick={() => handleRemoveSkills(index)}
                              >
                                <svg
                                  width="17"
                                  height="17"
                                  viewBox="0 0 17 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863"
                                    stroke="#ff0000"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            )}
                            <input
                              placeholder="Skills list"
                              value={item}
                              onChange={(e) =>
                                handleSkillsChange(index, e.target.value)
                              }
                              className="outline-none w-full h-[2.7rem] border border-[#CFD5E2] my-1 px-5 py-3 rounded-full"
                            />
                                </li>
                              )
                            })
                          }
                        
                        </ul>
                        <div
                        className="h6 cursor-pointer font-Mluvka text-secondary"
                        onClick={handleAddSkills}
                        >
                        + Add More Skills
                      </div>
                        </div>
                        <div className="teaamBox">
                        <div className="h2 text-primary text-[1.2rem]">MEMBERSHIPS</div>
                        <ul className="list">
                          {
                            Array.isArray(memberships) && memberships.map((item,index) => {
                              return (
                                <li className="relative" key={index}>
                      {index !== 0 && (
                              <div
                                className="close absolute right-[1rem] top-[50%] translate-y-[-50%] cursor-pointer"
                                onClick={() => handleRemoveMemberships(index)}
                              >
                                <svg
                                  width="17"
                                  height="17"
                                  viewBox="0 0 17 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863"
                                    stroke="#ff0000"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            )}
                            <input
                              placeholder="Memberships list"
                              value={item}
                              onChange={(e) =>
                                handleMembershipsChange(index, e.target.value)
                              }
                              className="outline-none w-full h-[2.7rem] border border-[#CFD5E2] my-1 px-5 py-3 rounded-full"
                            />
                                </li>
                              )
                            })
                          }
                        
                        </ul>
                        <div
                        className="h6 cursor-pointer font-Mluvka text-secondary"
                        onClick={handleAddMemberships}
                        >
                        + Add More Memberships
                      </div>
                        </div>
                        <div className="teaamBox">
                        <div className="h2 text-primary text-[1.2rem]">PRACTICE AREA</div>
                        <ul className="list">
                          {
                            Array.isArray(practicesarea) && practicesarea.map((item,index) => {
                              return (
                                <li className="relative" key={index}>
                      {index !== 0 && (
                              <div
                                className="close absolute right-[1rem] top-[50%] translate-y-[-50%] cursor-pointer"
                                onClick={() => handleRemovePracticesarea(index)}
                              >
                                <svg
                                  width="17"
                                  height="17"
                                  viewBox="0 0 17 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863"
                                    stroke="#ff0000"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            )}
                            <input
                              placeholder="Practice list"
                              value={item}
                              onChange={(e) =>
                                handlePracticesareaChange(index, e.target.value)
                              }
                              className="outline-none w-full h-[2.7rem] border border-[#CFD5E2] my-1 px-5 py-3 rounded-full"
                            />
                                </li>
                              )
                            })
                          }
                        
                        </ul>
                        <div
                        className="h6 cursor-pointer font-Mluvka text-secondary"
                        onClick={handleAddPracticesarea}
                        >
                        + Add More Practices Area
                      </div>
                        </div>
                      </div>


                  </div>
                 
                </div>
                
                <ul className="list flex justify-end gap-4 items-center pb-6 px-5">
                  <li className="">
                    {(
                      <SubmitButton
                        props={{
                          class:
                            "btn bg-secondary text-white  uppercase   py-3 px-8 rounded-full w-full block submit hover:bg-primary transition-all duration-300",
                          text: "Submit",
                        }}
                        buttonLoading={res.isLoading}
                      />
                    )}
                  </li>
                </ul>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateTeam
