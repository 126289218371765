import React, { useState, createContext } from "react";

export const MainBookingContext = createContext();

const MainBookingProvider = ({ children }) => {
  const [bookingContext, setBookingContext] = useState(null);

  const handleBooking = (data) => {
    setBookingContext(data);
  };

  return (
    <MainBookingContext.Provider value={{ bookingContext, handleBooking }}>
      {children}
    </MainBookingContext.Provider>
  );
};

export default MainBookingProvider;
