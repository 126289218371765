import React, { useEffect } from 'react'
import swal from 'sweetalert';
import win from "../dist/webImages/win.svg"
import { Link } from 'react-router-dom';
import noimg from '../dist/webImages/no_image.jpg'
import useDelete from '../customHooks/useDelete';
import { toast } from 'react-toastify';
import { StringConvert } from '../components/StringConvert';


const truncateText = (text, maxLength) => {
  if(text) {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + '...';
  }
  return text;
};


const LawyersCard = ({data,page,alldata,deleted}) => {
  const {id,lawyer_email,detail,number_of_cases,lowyer_image,name,expertise,designation} = data
  const [resDelete, apiMethodDelete] = useDelete()
  const handleDelete = () => {
    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete?",
      buttons: true,
      icon: "warning",
      dangerMode: true,
    })
    .then(willDelete => {
      if (willDelete) {
        swal("Successfully Delete", "", "success");
        apiMethodDelete(`teams/${id}`)
      }
    });
  }
  useEffect(() => {
    if(resDelete.data) {
      const {message} = resDelete.data
      const update = alldata.filter((item) => item.id !== id)
      deleted(update)
      toast.success(message);
    }
  }, [resDelete.data])

  
    const maxLength = 200;
    const truncatedText = truncateText(detail, maxLength);
  
  
  return (
    <div className='lawyerCard h-full relative bg-[#fff] border border-[#D4DEF1] rounded-3xl shadow-custom p-4'>
      {page !== "home" && 
      <div className='closeButton cursor-pointer absolute bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem] top-[.7rem] right-[.7rem] grid place-items-center rounded-[.7rem] z-10' onClick={handleDelete}>
      <svg className='w-full h-full' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863" stroke="#ED2656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </div>}
      <Link to={`/team/edit/${id}`} className='cursor-pointer relative'  >
        <div className="lawyerCard_ flex items-center gap-4 pr-10">
        <div className="lawyerCard__img">
        <img   className='h-[3.5rem] w-[3.5rem] object-contain rounded-sm' src={lowyer_image}   onError={(e) => { e.target.src = noimg }}  alt={name} />
      </div>
      <div className="lawyerCard__txt" >
            <div className="h2 text-[1.25rem] font-Mluvka leading-[1]">{name}</div>
            {lawyer_email && <p className='leading-[1] mt-2 text-primary text-[.8rem]'>{lawyer_email}</p>}
            <p className='leading-[1] mt-2 text-primary text-[.8rem]'>{designation}</p>
      </div>
        </div>
        {page !== "home" &&  <>
        {/* <div className='my-4 border border-primary w-fit py-3 px-6 rounded-2xl flex items-center gap-2' >
          <img src={win} alt="win" />
          <span className='font-Mluvka'>{number_of_cases}+ Cases</span>
        </div> */}
        <div className='text-[.9rem] text-[#3C3E56] mb-4 min-h-[7.2rem] mt-5'>{StringConvert(truncatedText)}</div>
        <div className="h3 font-MluvkaBold text-[#120D26]">Experties</div>
      
        <div className='lawyerCardbutton mt-4 flex flex-wrap gap-2'>
        {expertise.map((item,index) => {
        return (
          <div key={index} className='text-[.8rem] bg-primary text-white py-3 px-6 rounded-2xl'>{item}</div>
        )
       })
      }
      </div>
        </> }
        </Link>
    </div>
  )
}

export default LawyersCard
