import React, { useContext, useEffect, useState } from 'react'
import back from "../dist/webImages/back.svg";
import {  Link, useNavigate } from 'react-router-dom';
import { Formik,Form, Field } from 'formik';
import FormControl from '../components/form/FormControl';
import { MainLanguageContext } from '../context/MainLanguageContext';
import useFetch from '../customHooks/useFetch';
import noimg from '../dist/webImages/no_image.jpg'
import plus from '../dist/webImages/plus.svg'
import swal from "sweetalert";
import SubmitButton from '../components/SubmitButton';
import usePost from '../customHooks/usePost';
import { toast } from 'react-toastify';
import { Select } from 'antd';
const { Option } = Select;

const CreateCase = () => {
    const { mainLanguage } = useContext(MainLanguageContext); 
    const [oldNewClient, setOldNewClient] = useState("old")
    const navigate = useNavigate();
        const { loading:loading2, data:data2 } = useFetch(`caseManagements/userList`);
        const { loading, data } = useFetch(`teams/en/100?page=1`);
        const { loading3, data:data3 } = useFetch(`legalSecretaries/en/100?page=1`);

    const [addLowyer, setAddLowyer] = useState({
        "addLowyerStatus": false,
        "LowyerList": []
    })
    const [addLegalSecretary, setAddLegalSecretary] = useState({
        "addLegalSecretaryStatus": false,
        "LegalSecretaryList": []
    })
    const [lowyers, setLowyers] = useState([])
    const [legalSecretary, setLegalSecretary] = useState([])
    const { addLowyerStatus, LowyerList } = addLowyer
    const { addLegalSecretaryStatus, LegalSecretaryList } = addLegalSecretary

    useEffect(() => {
        if(data) {
         setAddLowyer((prevdata) => ({ ...prevdata,  LowyerList: data?.data?.teams}));
        }
         }, [data])
    useEffect(() => {
        if(data3) {
          setAddLegalSecretary((prevdata) => ({ ...prevdata,  LegalSecretaryList: data3?.data}));
        }
         }, [data3])
         

    let initialValues = {
        client_id:"",
        case_number:"",
        case_title:"",
        client_email:"",
    }


    const handleOLdNew = (e) => {
        setOldNewClient(e.target.value)
    }


    const handleLawyersToggle = (id) => {
        setLowyers(prevState => {
            const isActive = prevState.some(item => item.id === id);
            if (isActive) {
                return prevState.filter(item => item.id !== id);
            } else {
                const newLowyer = LowyerList.find(item => item.id === id);
                return [...prevState, newLowyer];
            }
        });
    }
    const handleLegalSecretaryToggle = (id) => {
      setLegalSecretary(prevState => {
            const isActive = prevState.some(item => item.id === id);
            if (isActive) {
                return prevState.filter(item => item.id !== id);
            } else {
                const newSecretaryList = LegalSecretaryList.find(item => item.id === id);
                return [...prevState, newSecretaryList];
            }
        });
    }

    const handleRemoveLowyers = (index) => {
        setLowyers(prevExpertise => prevExpertise.filter((_, i) => i !== index));
    };
    const handleRemoveLegalSecretary = (index) => {
        setLegalSecretary(prevExpertise => prevExpertise.filter((_, i) => i !== index));
    };
    const [selectedClientId, setSelectedClientId] = useState(null); // State to save the selected client ID

    const handleChange = (value) => {
      setSelectedClientId(value); // Save selected client ID to state
    };

    const [res, apiMethod] = usePost()
    const handleSubmit = async (values) => {
        let formdata = new FormData();
        let checkerRequried = [];
        if(oldNewClient === "old") {
            let requireFeild = ["case_number","case_title"];
            let requireFeildSwal = {
                case_number: "Case Number",
                case_title: "Case Name",
              };
              for (const item in values) {
                formdata.append(item, values[item]);
                if (requireFeild.includes(item) && !values[item]) {
                  checkerRequried.push(requireFeildSwal[item]);
                }
            }
            
        }
        else {
            let requireFeild = ["case_number","case_title","client_email","client_name"];
            let requireFeildSwal = {
                case_number: "Case Number",
                case_title: "Case Name",
                client_id: "Client Name",
                client_email: "Client Email",
              };

              for (const item in values) {
                if(item === "client_id") {

                }
                else {
                    formdata.append(item, values[item]);
                }
                if (requireFeild.includes(item) && !values[item]) {
                  checkerRequried.push(requireFeildSwal[item]);
                }
            }

        }
       
        
        if(lowyers.length === 0) {
            checkerRequried.push("Team");
        }
        if(legalSecretary.length === 0) {
            checkerRequried.push("Legal Secretary");
        }

        if(oldNewClient === "old") {
          if(selectedClientId) {
                formdata.append("client_id", selectedClientId);
            }
            else {
                checkerRequried.push("Client Name");
            }
        }
        else {

        }
          if (checkerRequried.length > 0) {
            swal({
              title: "Required Fields are empty! Please fill and try again",
              text: checkerRequried.join(","),
              icon: "error",
              dangerMode: true,
            });
          }
          else {
            for (let index = 0; index < lowyers.length; index++) {
                formdata.append("team_member[]", lowyers[index]?.id ?? "");
              }
            for (let index = 0; index < legalSecretary.length; index++) {
                formdata.append("legal_secretary[]", legalSecretary[index]?.id ?? "");
              }
            apiMethod(`caseManagements/create`, formdata)
          }
    }

    useEffect(() => {
        if (res.data) {
          const { status, message } = res?.data
          if (status === "false") {
            toast.error(message);
          }
          else {
            navigate("/case")
            toast.success(message);
          }
        }
      }, [res.data])

    if(loading || loading2 || loading3) return "" 
        return (
        <div className='newscreate pr-10 max-lg:pr-6'>
            <Link to={"/case"} className="back flex items-center mb-6 gap-2">
                <img src={back} className='w-[2rem]' alt="" />
                <span className='text-[1.4rem] font-MluvkaBold'>Create Case</span>
            </Link>
            <div className='relative flex gap-3'>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form className='w-full' name="myForm">
             
            <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3 max-lg:p-3'>
            <div className='flex items-center gap-[2rem] mb-6'>
            <div className="flex items-center">
    <input id="default-radio-1" type="radio" value="old" checked={oldNewClient === "old"} onChange={handleOLdNew} name="default-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300" />
    <label htmlFor="default-radio-1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Mobile Client</label>
</div>
<div className="flex items-center">
    <input  id="default-radio-2" type="radio" value="new" checked={oldNewClient === "new"} onChange={handleOLdNew} name="default-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300" />
    <label htmlFor="default-radio-2" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">New Client</label>
</div>
                </div>



                {
                    oldNewClient === "old" 
                    ?
                    <div className=''>
                    <span className="text-[#95ACD5] font-Mluvka text-[.8rem]">Client</span> 
                    <Select
          name="client_id"
          className="w-full rounded-xl h-[2.8rem] px-0 outline-0 capitalize appearance-none max-lg:text-[.9rem]"
          placeholder="Select Client"
          onChange={handleChange} // Set the selected value in state
        >
          {Array.isArray(data2?.data) && data2?.data.map((item, index) => {
            const { client_id, client_name, profile_image } = item;
            return (
              <Option value={client_id} key={index}>
                <div className="flex items-center space-x-2">
                  <img
                    src={profile_image} 
                    alt={client_name} 
                    onError={(e) => { e.target.src = noimg }}
                    className="w-6 h-6 rounded-full object-cover"
                  />
                  <span>{client_name}</span>
                </div>
              </Option>
            );
          })}
        </Select>
                    </div>

                    :

                    <>
                    
                    <FormControl name="client_name" label={"Client Name"} placeholder="Enter Client Name" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                    <FormControl name="client_email" label={"Client Email"} placeholder="Enter Client Email" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                    </>


                }
                    <FormControl name="case_number" label={"Case Number"} placeholder="Enter Case Number" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                    <FormControl name="case_title" label={"Case Name"} placeholder="Enter Case Name" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
            </div>

            <div className='flex items-center gap-5 mt-3 max-lg:flex-col'>
                                                <div className='border border-[#CFD5E2] py-3 px-6 rounded-full flex items-center gap-2 cursor-pointer' onClick={() => setAddLowyer(d => ({ ...d, addLowyerStatus: !addLowyerStatus }))}>
                                                    <img src={plus} alt="plus" />
                                                    <span className='font-MluvkaBold text-secondary capitalize'>Add Teams</span>
                                                </div>
                                                <ul className='list flex max-lg:flex-wrap gap-2'>
                                                    {lowyers && lowyers.map((item, index) => {
                                                        const { id, lowyer_image } = item;
                                                        return (
                                                            <li className="dpartCardProfile-img relative" key={id}>
                                                                <div className='close w-[1.2rem] h-[1.2rem] grid place-items-center p-1 absolute top-[-.2rem] right-[-.2rem] bg-[#FF9898] rounded-3xl cursor-pointer' onClick={() => handleRemoveLowyers(index)}>
                                                                    <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M6.22583 1.00391L1.19141 6.03799M6.22583 6.03833L1.19141 1.00423" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    </svg>
                                                                </div>
                                                                <img className={`w-[2.8rem] h-[2.8rem] min-w-[2.8rem] min-h-[2.8rem] rounded-full object-cover border-2 border-[#fff]`} src={lowyer_image} alt={id} />
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
            <div className='flex items-center gap-5 mt-3 max-lg:flex-col'>
                                                <div className='border border-[#CFD5E2] py-3 px-6 rounded-full flex items-center gap-2 cursor-pointer' onClick={() => setAddLegalSecretary(d => ({ ...d, addLegalSecretaryStatus: !addLegalSecretaryStatus }))}>
                                                    <img src={plus} alt="plus" />
                                                    <span className='font-MluvkaBold text-secondary capitalize'>Add Legal Secretary</span>
                                                </div>
                                                <ul className='list flex max-lg:flex-wrap gap-2'>
                                                    {legalSecretary && legalSecretary.map((item, index) => {
                                                        const { id, legal_secretary_image } = item;
                                                        return (
                                                            <li className="dpartCardProfile-img relative" key={id}>
                                                                <div className='close w-[1.2rem] h-[1.2rem] grid place-items-center p-1 absolute top-[-.2rem] right-[-.2rem] bg-[#FF9898] rounded-3xl cursor-pointer' onClick={() => handleRemoveLegalSecretary(index)}>
                                                                    <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M6.22583 1.00391L1.19141 6.03799M6.22583 6.03833L1.19141 1.00423" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    </svg>
                                                                </div>
                                                                <img className={`w-[2.8rem] h-[2.8rem] min-w-[2.8rem] min-h-[2.8rem] rounded-full object-cover border-2 border-[#fff]`} src={legal_secretary_image} alt={id} />
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>

                                 
                                            {
          <SubmitButton
            props={{
                class: "btn mt-2 ml-auto bg-secondary text-white  uppercase   py-3 px-10 rounded-full w-fit block submit hover:bg-primary transition-all duration-300",
                text: "Add",
                }}
                buttonLoading={res.isLoading} />
        }
            </Form>
            </Formik>
            <div className='bg-[#D4DEF1] w-0 overflow-hidden  transition-all duration-700  self-stretch rounded-xl max-lg:absolute max-lg:right-0 max-lg:w-[20rem!important]' style={{ width: addLowyerStatus ? "30%" : "0" }}>
                        {addLowyerStatus && <div className='px-3 h-full  py-4'>
                            <div className="h4 text-[1.1rem] font-MluvkaBold mb-4">
                                <span>Teams</span>
                                <div className='closeButton cursor-pointer absolute bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem] top-[.7rem] right-[.7rem] grid place-items-center rounded-[.7rem] z-10' onClick={() => setAddLowyer(d => ({ ...d, addLowyerStatus: !addLowyerStatus }))}>
                                    <svg className='w-full h-full' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863" stroke="#ED2656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                            <ul className='list lowyerlist overflow-y-auto max-h-[50vh] pr-2'>
                                {Array.isArray(LowyerList) && LowyerList.map((item) => {
                                    const { id, name, lowyer_image, designation } = item;
                                    const lowyerslistactive = lowyers.map(l => l.id);
                                    return (
                                        <li key={id} className='bg-white cursor-pointer my-3 border border-white rounded-3xl py-2 px-4' onClick={() => handleLawyersToggle(id)} style={{ borderColor: lowyerslistactive.includes(id) ? "#DCB33E" : "white" }}>
                                            <div className="lawyerCard_ flex items-center gap-4">
                                                <div className="lawyerCard__img">
                                                    <img className='w-[2.5rem] h-[2.5rem] object-cover rounded-sm' onError={(e) => { e.target.src = noimg }} src={lowyer_image} alt={name} />
                                                </div>
                                                <div className="lawyerCard__txt" >
                                                    <div className="h2 text-[.9rem] font-Mluvka leading-[1]">{name}</div>
                                                    <p className='leading-[1] mt-1 text-primary text-[.8rem]'>{designation}</p>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}

                            </ul>
                        </div>}
                    </div>
            <div className='bg-[#D4DEF1] w-0 overflow-hidden  transition-all duration-700  self-stretch rounded-xl max-lg:absolute max-lg:right-0 max-lg:w-[20rem!important]' style={{ width: addLegalSecretaryStatus ? "30%" : "0" }}>
                        {addLegalSecretaryStatus && <div className='px-3 h-full  py-4'>
                            <div className="h4 text-[1.1rem] font-MluvkaBold mb-4">
                                <span>Legal Secretary</span>
                                <div className='closeButton cursor-pointer absolute bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem] top-[.7rem] right-[.7rem] grid place-items-center rounded-[.7rem] z-10' onClick={() => setAddLegalSecretary(d => ({ ...d, addLegalSecretaryStatus: !addLegalSecretaryStatus }))}>
                                    <svg className='w-full h-full' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863" stroke="#ED2656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                            {console.log(LegalSecretaryList)}
                            <ul className='list lowyerlist overflow-y-auto max-h-[50vh] pr-2'>
                                {Array.isArray(LegalSecretaryList) && LegalSecretaryList.map((item) => {
                                    const { id, name, legal_secretary_image, designation } = item;
                                    const lowyerslistactive = legalSecretary.map(l => l.id);
                                    return (
                                        <li key={id} className='bg-white cursor-pointer my-3 border border-white rounded-3xl py-2 px-4' onClick={() => handleLegalSecretaryToggle(id)} style={{ borderColor: lowyerslistactive.includes(id) ? "#DCB33E" : "white" }}>
                                            <div className="lawyerCard_ flex items-center gap-4">
                                                <div className="lawyerCard__img">
                                                    <img className='w-[2.5rem] h-[2.5rem] object-cover rounded-sm' onError={(e) => { e.target.src = noimg }} src={legal_secretary_image} alt={name} />
                                                </div>
                                                <div className="lawyerCard__txt" >
                                                    <div className="h2 text-[.9rem] font-Mluvka leading-[1]">{name}</div>
                                                    <p className='leading-[1] mt-1 text-primary text-[.8rem]'>{designation}</p>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}

                            </ul>
                        </div>}
                    </div>
        </div>
       
        </div>
    )
}

export default CreateCase