import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import back from "../dist/webImages/back.svg";
import { Link } from 'react-router-dom';
const SkeletonCreateEdit = ({heading}) => {
  return (
    <SkeletonTheme baseColor="#ddd" highlightColor="#eee">
    <div className='createTeam pr-10 max-lg:pr-6'>
    <Link to={"/team"} className="back flex items-center mb-5 gap-2">
            <img src={back} className='w-[2rem]' alt="" />
            <span className='text-[1.4rem] font-MluvkaBold'>{heading}</span>
            </Link>
      <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3 max-lg:p-2'>
        <div className='TeamModel bg-white rounded-xl'>
          <div className="p-5">
            <div className="w-[5rem] h-[5rem] relative mt-4">
              <Skeleton style={{borderRadius:"1rem"}} height={80} width={80} />
            </div>
            <div className="form mt-7">
              <Skeleton height={48} className="w-full mb-3" />
              <Skeleton height={48} className="w-full mb-3" />
              <Skeleton height={48} className="w-full mb-3" />
              <Skeleton height={160} className="w-full mb-3" />
              <Skeleton count={3} height={48} className="w-full mb-3" />
              <div className="mt-4">
                <Skeleton width={120} height={32} />
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-4 items-center pb-6 px-5">
            <Skeleton width={120} height={48} />
          </div>
        </div>
      </div>
    </div>
  </SkeletonTheme>
  )
}

export default SkeletonCreateEdit
